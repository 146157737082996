import React, {useState, useEffect} from "react";
import axios from "axios";
import AIchat_Icon from "../../../assets/Success Popup/AI_chart_Icon.svg";
import userAvatar from "../../../assets/Success Popup/userAvatar.svg";
import Send_button from "../../../assets/Success Popup/send_button.svg";


const GPT = () => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const apiKey = "segrdhyfj"
 

  useEffect(() => {
    const welcomeMessage = {
      role: "assistant",
      content: "Welcome to Beartactic AI!",
      time: getCurrentTime(),
    };
    setMessages([welcomeMessage]);
  }, []);

  const getCurrentTime = () => {
    const now = new Date();
    return now.toLocaleTimeString([], {hour: "2-digit", minute: "2-digit"});
  };

  const sendMessage = async () => {
    if (!inputMessage.trim()) return;
    const userMessage = {
      role: "user",
      content: inputMessage,
      time: getCurrentTime(),
    };
    setMessages([...messages, userMessage]);
    setInputMessage("");
    setLoading(true);

    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-3.5-turbo",
          messages: [...messages, userMessage],
        },
        {
          headers: {
            Authorization: `Bearer ${apiKey}`,
            "Content-Type": "application/json",
          },
        },
      );

      const botMessage = response.data.choices[0].message;
      botMessage.time = getCurrentTime();
      setMessages((prevMessages) => [...prevMessages, botMessage]);
    } catch (error) {

    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="right-section">
      <div className="ai-chat-container">
        <div className="ai-chat-header">
          <div className="ai-chat-header_for_BeartacticAI">
            <img
              src={AIchat_Icon}
              alt="AI icon"
              className="ai-chat-icon"
            />
            <h3>Beartactic AI</h3>
          </div>
          <button className="maximize-btn">⤢</button>
        </div>

        <div className="chat-messages">
          {messages.map((msg, idx) => (
            <div
              key={idx}
              className={`message-wrapper ${msg.role === "user" ? "user-message-wrapper" : "ai-message-wrapper"}`}
            >
              <div className={`message ${msg.role === "user" ? "user-message" : "ai-message"}`}>
                {msg.role === "assistant" && (
                  <img
                    src={AIchat_Icon}
                    alt="AI icon"
                    className="ai-chat-icon"
                  />
                )}
                <div className={msg.role === "user" ? "chat-text-container-user" : "chat-text-container"}>
                  <p>{msg.content}</p>
                </div>
                {msg.role === "user" && (
                  <img
                    src={userAvatar}
                    alt="User avatar"
                    className="user-avatar"
                  />
                )}
              </div>
              {/* <span
                className={msg.role === "user" ? "chat-time-user" : "chat-time"}
                style={{ bottom: "0", right: "0", fontSize: "12px", color: "#999"}}
              >
                {msg.time}
              </span> */}
            </div>
          ))}
          {loading && (
            <div className="loading-message">
              <span className="dot">.</span>
              <span className="dot">.</span>
              <span className="dot">.</span>
            </div>
          )}
        </div>

        <div className="chat-input">
          <input
            type="text"
            placeholder="Type your message here..."
            className="chat-input-field"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyPress={(e) => e.key === "Enter" && sendMessage()}
          />
          <img
            src={Send_button}
            alt="Send button"
            onClick={sendMessage}
          />
        </div>
      </div>
    </div>
  );
};

export default GPT;

import React from "react";
import {useSelector, useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import "./Deals.scss";
import dot from "../../assets/svg/dot.svg";
import redirect from "../../assets/acqusition/svg/redirect.svg";
import dealcard from "../../assets/Image/deals/dealcard.png";
import importerIcon from "../../assets/dealsroom/svg/importedGreenIcon.svg";
import {format} from "date-fns";
import {setReadToInvest} from "../../store/reducers/userDetailsSlice";

const AcquitionDealCard = ({data, roleType}) => {
  const profileimage = useSelector((state) => state.auth.userInformation.profile_image);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formattedDate = data?.date ? format(new Date(data.date), "MMMM dd, yyyy") : "";

  const handleSelectAndNavigate = () => {
    dispatch(setReadToInvest(true));
    navigate(`/${data._id}/dealsroom`);
  };

  return (
    <div
      className="deal-card acquitionDealCard_wrapper"
      onClick={handleSelectAndNavigate}
    >
      <div className="deal-card-upper">
        <img
          src={data?.assetsImages?.[0] ? data.assetsImages[0] : dealcard}
          alt="Asset"
        />
        <div className="deal-c-u-t">
          <span>{data?.assetName}</span>
          <span>{data?.assetsAddress}</span>
        </div>
        <div className="dot">
          <img
            src={dot}
            alt=""
          />
        </div>
      </div>
      <div className="acquitionDealCard_info">
        <div className="acquitionDealCard_center">
          <div className="upperSection">
            <div className="priceInfo">
              <h4>
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(data?.assetValue)}
              </h4>

              <div className="status">
                <div className="statusIndicator">
                  <div className="innerCircle"></div>
                </div>
                <p>Pending</p>
              </div>
            </div>
            <div className="propertyInfo">
              <div className="propBox">
                <div className="proplabel">Property type</div>
                <div className="propvalue">{data?.property_type}</div>
              </div>
              <div className="propBox">
                <div className="proplabel">Market</div>
                <div className="propvalue">Baltimore</div>
              </div>
            </div>
          </div>
          <div className="bottomSection"></div>
        </div>
        <div className="acquitionDealCard_bottom">
          <div className="importerInfo">
            <img
              src={profileimage}
              className="importerPic"
              alt="importerPic"
            />
            <div className="importerDetail">
              <div className="topSection">
                <img
                  src={importerIcon}
                  alt="icon"
                />
                <p>
                  Imported <span className="importDate">{formattedDate}</span>
                </p>
              </div>
              <div className="bottomSection">
                <p>
                  by <span className="importerName">{data?.Owner}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="acquitionDealCard_btnBox">
        <button onClick={handleSelectAndNavigate}>
          View Deal
          <img
            src={redirect}
            alt="redirect"
          />
        </button>
      </div>
    </div>
  );
};

export default AcquitionDealCard;

import React, {useState, useEffect} from "react";
import ReactMarkdown from "react-markdown";
import ThreeDotMenu from "../ThreeDotMenu";
import {ReactComponent as RegenBtn} from "../../../assets/chatCompAssets/icons/regenBtnIcon.svg";
import {ReactComponent as LikeBtn} from "../../../assets/chatCompAssets/icons/likebtnIcon.svg";
import {ReactComponent as DislikeBtn} from "../../../assets/chatCompAssets/icons/dislikebtnIcon.svg";
import {renderLineChart, renderPieChart} from "../../../components/ApexChart/ChatsForAI";
import FeedbackForm from "../Popup/Feedback";
import "./KeyWords.scss";
import Endpoint from "../../../utils/Endpoint";
import InviteacolleaguetoAsset from "../Popup/InviteacolleaguetoAsset";
import {useSelector} from "react-redux";
import axios from "axios";
import useAuthConfig from "../../../utils/Config";

const KeywordMessage = ({message, keyword, index, menuVisibleIndex, onMenuToggle, onAssignToTeam, handleRegenChat}) => {
  const [intro, details] = message.text.includes(keyword) ? message.text.split(keyword) : [message.text, ""];
  const [selectedText, setSelectedText] = useState(null);
  const [statuses, setStatuses] = useState([]);
  const [loadingStatuses, setLoadingStatuses] = useState(false);
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
  const {idToken} = useSelector((state) => state.auth);
  const {userInformation} = useSelector((state) => state.auth);
  const {selectedProperty} = useSelector((state) => state.portfolio);
  const config = useAuthConfig();
  const [liked, setLiked] = useState(false);

  const extractData = (details) => {
    try {
      const pieMatch = details.match(/"pieChartData":\s*(\[[\s\S]*?\])/);
      const lineMatch = details.match(/"lineChartData":\s*(\{[\s\S]*?\})/);
      const pieData = pieMatch ? JSON.parse(pieMatch[1]) : null;
      const lineData = lineMatch ? JSON.parse(lineMatch[1]) : null;
      return {pieData, lineData};
    } catch (error) {
      return {pieData: null, lineData: null};
    }
  };

  const extractRecommendations = (details) => {
    try {
      const recommendationMatches = details?.match(/```json([\s\S]*?)```/g) || [];
      return recommendationMatches?.map((match) => JSON.parse(match.replace(/```json|```/g, "").trim()));
    } catch (error) {
      return [];
    }
  };

  const recommendations = extractRecommendations(details);
  const {pieData, lineData} = extractData(details);

  const handleParagraphClick = (text, id = null) => {
    const header = text?.match(/[^.!?]+[.!?]/)?.[0].trim() || text?.split(/\s+/)?.slice(0, 10)?.join(" ") + "..." || "No Header Available";

    setSelectedText({
      id: id || "unknown_id",
      header,
      text: text?.trim() || "No Text Available",
    });
  };

  const handleMenuSelect = (recommendation) => {
    const id = recommendation.id || "unknown_id";
    const header = recommendation.header || recommendation.text?.split(/\s+/)?.slice(0, 10)?.join(" ") + "..." || "No Header Available";
    const description = recommendation.text || "No Text Available";

    setSelectedText({
      id,
      header,
      text: description,
    });
  };

  // get all status -

  useEffect(() => {
    fetchStatuses();
  }, [message?.Message_id, message?.text_id, userInformation?._id, selectedProperty?._id, idToken]);

  const fetchStatuses = async () => {
    setLoadingStatuses(true);
    try {
      const response = await axios(`${Endpoint?.getAllstatus}?Message_id=${message?.msg_id}&user_id=${userInformation?._id}&asset_id=${selectedProperty?._id}`, config, {});
      setStatuses(response?.data?.data || []);
    } catch (error) {
    } finally {
      setLoadingStatuses(false);
    }
  };

  // Like

  const handleLikeClick = async () => {
    try {
      setLiked(true);

      const response = await axios.post(
        `${Endpoint.Likechat}?user_id=${userInformation?._id}&asset_id=${selectedProperty?._id}&Message_id=${message?.msg_id}`,
        {}, 
        config,
      );

      console.log(response.data);
    } catch (error) {
      setLiked(false);
      console.error(error.response?.data || error.message); 
    }
  };

  const handleDislikeClick = () => {
    setLiked(false);
    setIsFeedbackOpen(true);
  };

  return (
    <div className="keyword-message">
      <ReactMarkdown className="paragraph">{intro}</ReactMarkdown>

      <div className="keyword-details">
        <h3>Recommendations:</h3>

        <ol>
          {recommendations?.map((rec, index) =>
            rec.header && rec.description ? (
              <li
                key={rec.id || index}
                style={{marginBottom: "1em"}}
              >
                <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                  <strong>{rec.header}</strong>
                  <div
                    className="status-container"
                    style={{marginRight: "1em"}}
                  >
                    <div className="Recommendations_Container">
                      {statuses
                        ?.filter((status) => status?.text_id === rec?.id)
                        ?.slice(0, 1)
                        ?.map((status) => (
                          <span
                            key={status?._id}
                            className={`Recommendations_status ${status?.status}`}
                          >
                            <span className="Recommendations_status_text">{status?.status}</span>
                          </span>
                        ))}
                      {statuses?.filter((status) => status?.text_id === rec?.id)?.length > 1 && (
                        <span className="plusonemore">+{statuses.filter((status) => status?.text_id === rec?.id).length - 1}</span>
                      )}
                      <ThreeDotMenu
                        onSelect={() => handleMenuSelect(rec)}
                        selectedText={selectedText}
                        handleParagraphClick={() => handleParagraphClick(rec.description, rec.id)}
                        messageid={message?.msg_id}
                        text_id={selectedText?.id}
                        fetchStatuses={fetchStatuses}
                      />
                    </div>
                  </div>
                </div>
                <p>{rec?.description}</p>
              </li>
            ) : null,
          )}
        </ol>

        <h3>Data Visualizations:</h3>
        {pieData && (
          <div className="KeyWords_piecharts">
            <h4>Pie Chart</h4>
            {renderPieChart(pieData)}
          </div>
        )}
        {lineData && (
          <div className="KeyWords_LineGraph">
            <h4>Line Graph</h4>
            {renderLineChart(lineData)}
          </div>
        )}
      </div>

      <div className="right-side-buttons">
        <button
          className="regen-btn"
          onClick={handleRegenChat}
        >
          <RegenBtn />
        </button>

        <button
          className="like-btn"
          onClick={handleLikeClick}
        >
          <LikeBtn />
        </button>
        {!liked && (
          <button
            className="dislike-btn"
            onClick={handleDislikeClick}
          >
            <DislikeBtn />
          </button>
        )}
      </div>

      <FeedbackForm
        open={isFeedbackOpen}
        onClose={() => setIsFeedbackOpen(false)}
        messageid={message?.msg_id}
      />
      {isInviteDialogOpen && (
        <InviteacolleaguetoAsset
          open={isInviteDialogOpen}
          onClose={() => setIsInviteDialogOpen(false)}
          fetchStatuses={fetchStatuses}
        />
      )}

      <div className="bubble-container">
        <div className="bubble">
          <p>This recommendation blends data on vacancy costs with an ad strategy to speed up leasing, thereby reducing lost revenue while keeping marketing expenses manageable.</p>
        </div>
      </div>
    </div>
  );
};

export default KeywordMessage;

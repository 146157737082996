import React, {useEffect, useState, useRef} from "react";
import {useDispatch} from "react-redux";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import {format} from "date-fns";
import {setSelectedDate} from "../../store/reducers/Task/SeletedTask";
import "./Kanban.scss";

const KanbanCalendar = ({getTaskdata, onDateClick}) => {
  const [events, setEvents] = useState([]);
  const calendarRef = useRef(null);
  const dispatch = useDispatch();

  const formatDate = (date) => {
    if (!date) return null;
    try {
      return format(new Date(date), "yyyy-MM-dd");
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    if (getTaskdata && getTaskdata.length > 0) {
      const groupedEvents = getTaskdata.reduce((acc, task) => {
        const taskDate = formatDate(task.date || task.deadline);
        if (!taskDate) return acc;

        if (!acc[taskDate]) {
          acc[taskDate] = {
            title: task.Name,
            desc: task.desc,
            time: task.createdAt.split(" ")[1], // Extract time
            count: 0,
            start: taskDate,
            extendedProps: {
              color: task.color,
              backgroundColor: task.backgroundColor,
            },
          };
        } else {
          acc[taskDate].count += 1; // Increment the count for additional tasks
        }

        return acc;
      }, {});

      const formattedEvents = Object.keys(groupedEvents).map((dateKey) => {
        const event = groupedEvents[dateKey];
        return {
          ...event,
          title:
            event.count > 0
              ? `${event.title}` // Show the first event name
              : event.title,
        };
      });

      setEvents(formattedEvents);
    }
  }, [getTaskdata]);

  const handleEventClick = (eventInfo) => {
    const eventData = {
      title: eventInfo.event.title,
      start: eventInfo.event.start,
      time: eventInfo.event.extendedProps.time,
      color: eventInfo.event.extendedProps.color,
      backgroundColor: eventInfo.event.extendedProps.backgroundColor,
    };

    dispatch(setSelectedDate(eventData));
  };

  const renderEventContent = (arg) => {
    const event = arg.event;
    const eventColor = event.extendedProps.color || "#34AA44";
    const eventBackgroundColor = event.extendedProps.backgroundColor || "#DFF5E1";

    return (
      <div>
        {/* Custom event box */}
        <div
          style={{
            cursor: "pointer",
            backgroundColor: eventBackgroundColor,
            color: eventColor,
            padding: "5px",

            boxShadow: `3px 0px 0px 0px ${eventColor} inset`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginLeft: "-2px",
          }}
        >
          <b className="task_data_font">{event.title.length > 10 ? `${event.title.slice(0, 10)}...` : event.title}</b>
          {/* Time displayed below the name */}
          <div>
            <p className="task_data_font_time"> at {event.extendedProps.time || "N/A"}</p>
          </div>
        </div>

        {/* +more displayed outside the box */}
        {event.extendedProps.count > 0 && (
          <div
            style={{
              marginTop: "5px",
              textAlign: "center",
            }}
          >
           <p className="plustask_more"> +{event.extendedProps.count} more...</p>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="kanban-calendar-wrapper">
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={events}
        dateClick={(arg) => onDateClick(formatDate(arg.date))}
        eventContent={renderEventContent}
        eventClick={handleEventClick}
        height={"auto"}
        headerToolbar={{start: "prev", center: "title", end: "next"}}
      />
    </div>
  );
};

export default KanbanCalendar;

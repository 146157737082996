import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: false,
    idToken: null,
    userInformation: null,
  },
  reducers: {
    loginRequest: (state, action) => {},
    login: (state, action) => {
      state.isAuthenticated = true; 
      state.idToken = state.idToken || action.payload.token; 
      state.userInformation = action.payload.userInformation || state.userInformation; 
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.idToken = null;
      state.userInformation = null;
    },
  },
});

export const { login, logout, loginRequest } = authSlice.actions;
export default authSlice.reducer;

import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import useAuthConfig from "../../utils/Config";
import Endpoint from "../../utils/Endpoint";

export const fetchUserDetails = createAsyncThunk("data/fetchDetails", async (idToken) => {
  const config = useAuthConfig();
  const response = await axios.get(Endpoint.getUserDetails, config);
  return response?.data?.data;
});

const userDetailsSlice = createSlice({
  name: "user_info",
  initialState: {
    loading: false,
    error: null,
    userInformation: [],
    refresh: false,
    userAssetData: null,
    chatId: null,
    notificationCount: 0,
    assetName: "",
    isChatopen: false,
    allowToStartGroup: false,
    assetId: null,
    ReadToInvest: false,
  },
  reducers: {
    setUserAssetData: (state, action) => {
      state.userAssetData = action.payload;
    },
    setChatId: (state, action) => {
      state.chatId = action.payload;
    },
    setNotificationCount: (state, action) => {
      state.notificationCount = action.payload;
    },
    setAssetName: (state, action) => {
      state.assetName = action.payload;
    },
    setIsChatOpen: (state, action) => {
      state.isChatopen = !state.isChatopen;
    },
    setAllowToStartGroup: (state, action) => {
      state.allowToStartGroup = action.payload;
    },
    setAssetId: (state, action) => {
      state.assetId = action.payload;
    },
    setToggleRefresh: (state, action) => {
      state.refresh = !state.refresh;
    },
    setReadToInvest: (state, action) => {
      state.ReadToInvest = action.payload;
    },
  },
  extraReducers: {
    [fetchUserDetails.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [fetchUserDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.userInformation = action.payload;
    },
    [fetchUserDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {setUserAssetData, setChatId, setNotificationCount, setAssetName, setIsChatOpen, setAllowToStartGroup, setAssetId, setToggleRefresh, setReadToInvest} = userDetailsSlice.actions;
export default userDetailsSlice.reducer;

import React from "react";
// import bottomFirstt from "../Images/bottompic1.png";
import bottomFirst from "../../assets/acqusition/svg/bottomFirst.svg";
import bottomSecond from "../../assets/acqusition/svg/bottomSecond.svg";
import "./offComp.scss";

function OffmarketsCompo({item, index}) {
  return (
    <div className="off-compo">
      <img
        src={item.assetsImages[0]}
        alt="bottom"
      />

      <div className="heading">
        <p>{item?.assetName}</p>
      </div>

      <div className="disc">{item?.assetDescription.slice(0, 70) + "..."}</div>

      <div className="bottom-icons">
        <div>
          <img
            src={bottomFirst}
            alt="icon"
          />
          {/* <div className="">{squareFoot}</div> */}
          <div className="">0</div>
        </div>
        <div>
          <img
            src={bottomSecond}
            alt="icon"
            className=""
          />
          {/* <div className="">{units}</div> */}
          <div className="">0 Units</div>
        </div>
      </div>

      <hr id="line" />

      <div className="last-bottom">
        <div className="last-left">${item?.assetValue}</div>
        <button className="last-right">Asset Info</button>
      </div>
    </div>
  );
}

export default OffmarketsCompo;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  assets: [],
  loading: false,
  error: null,
};


export const fetchAssetsByAdminEmail = createAsyncThunk(
  "assets/fetchAssetsByAdminEmail",
  async ({ idToken, email }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `api/Portfolio/getAccessGivenByAdmin?email=${email}`,
        {
          headers: { "auth-token": idToken },
        }
      );
      if (response.status === 200) {
        return response.data.data || [];
      } else {
        return rejectWithValue("Failed to fetch assets");
      }
    } catch (error) {

      return rejectWithValue(error.message);
    }
  }
);

const assetsSlice = createSlice({
  name: "assets", 
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAssetsByAdminEmail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAssetsByAdminEmail.fulfilled, (state, action) => {
        state.loading = false;
        state.assets = action.payload;
      })
      .addCase(fetchAssetsByAdminEmail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default assetsSlice.reducer;

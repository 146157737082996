import React from "react";
import {Dialog, DialogTitle, DialogContent, Button, Typography, Box, Chip, Grid, IconButton, Divider} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {format} from "date-fns";
import "./TimeTableBar.scss";

export const TaskDetailsModal = ({isOpen, onClose, task}) => {
  const formatDateTime = (date) => (date ? format(new Date(date), "MMMM dd, yyyy hh:mm a") : "N/A");

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      className="taskmodelpopup-modal"
    >
      <DialogTitle className="taskmodelpopup-title">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6">Task Details</Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        dividers
        className="taskmodelpopup-content"
      >
        <Grid
          container
          spacing={2}
        >
          {/* Task Name */}
          <Grid
            item
            xs={12}
          >
            <Typography
              variant="subtitle1"
              color="textSecondary"
            >
              Task Name
            </Typography>
            <Typography variant="body1">{task?.Name || "N/A"}</Typography>
          </Grid>

          {/* Description */}
          <Grid
            item
            xs={12}
          >
            <Typography
              variant="subtitle1"
              color="textSecondary"
            >
              Description
            </Typography>
            <Typography variant="body1">{task?.desc || "N/A"}</Typography>
          </Grid>

          {/* Status */}
          <Grid
            item
            xs={12}
          >
            <Typography
              variant="subtitle1"
              color="textSecondary"
            >
              Status
            </Typography>
            <Typography variant="body1">{task?.status || "N/A"}</Typography>
          </Grid>

          {/* Assigned Employees */}
          <Grid
            item
            xs={12}
          >
            <Typography
              variant="subtitle1"
              color="textSecondary"
            >
              Assigned Employees
            </Typography>
            <Typography variant="body1">{task?.employee_id?.length > 0 ? task?.employee_id?.map((employee) => `${employee?.name} ${employee?.surname}`).join(", ") : "None"}</Typography>
          </Grid>

          {/* Date */}
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Typography
              variant="subtitle1"
              color="textSecondary"
            >
              Deadline
            </Typography>
            <Typography variant="body1">{formatDateTime(task?.date)}</Typography>
          </Grid>

          {/* Created At */}
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Typography
              variant="subtitle1"
              color="textSecondary"
            >
              Created At
            </Typography>
            <Typography variant="body1">{formatDateTime(task?.createdAt)}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: 2,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={onClose}
          className="taskmodelpopup-close-button"
        >
          Close
        </Button>
      </Box>
    </Dialog>
  );
};

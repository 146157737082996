import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import axios from "axios";
import ReactApexChart from "react-apexcharts";
import {Table} from "antd";
import LoaderOverlay from "../../../../components/reusableComponents/Loader";
import "./Maintenance.scss";
import Endpoint from "../../../../utils/Endpoint";

const Maintenance = ({selectedItem}) => {
  const {idToken} = useSelector((state) => state.auth);
  const userPermissions = useSelector((state) => state.portfolio.assetPermissions);
  const userInformation = useSelector((state) => state.userInfo.userInformation);
  const roleType = userInformation?.roleTypes;
  let {selectedProperty} = useSelector((state)=>state.portfolio);

  const [pieChart, setPieChart] = useState({
    series: [],
    chart: {
      width: 380,
      type: "donut",
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "bottom",
      formatter: function (val, opts) {
        return val + " - " + opts.w.globals.series[opts.seriesIndex] + "%";
      },
      itemMargin: {
        vertical: 0,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    labels: ["Under 24h", "24h to 48h", "Over 48h"],
  });

  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },
    xaxis: {
      categories: [],
      fontSize: "10px",
      background: "black",
    },
    colors: ["#7d55c7"],
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  });

  const [chartSeries, setChartSeries] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({current: 1, pageSize: 10, total: 0});

  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editPopupTitle, setEditPopupTitle] = useState("");

  const hasEditPermission = (section, permission) => {
    if (!Array.isArray(userPermissions)) return false;
    const foundPermission = userPermissions.find((perm) => perm.section === section);
    return foundPermission ? foundPermission.permissions[permission] === true : false;
  };

  const hasMaintenancePermission = roleType === 2 || hasEditPermission("Property Data Overview", "Maintenance");

  useEffect(() => {}, [hasMaintenancePermission]);

  useEffect(() => {
    const fetchData = async (page = 1, pageSize = 10) => {
      try {
        if (!selectedItem || !selectedItem._id) {
     
          setLoading(false);
          return;
        }

        const response = await fetchTableData(idToken, [selectedItem._id], page, pageSize);
        if (response && response.maintenanceData) {
          setTableData(response.maintenanceData);
          setPagination({
            current: page,
            pageSize: pageSize,
            total: response.totalUnits || 0,
          });
        }
      } catch (error) {

      } finally {
        setLoading(false);
      }
    };

    const fetchChartDataAsync = async () => {
      try {
        if (!selectedItem || !selectedItem._id) {
 
          return;
        }

        const response = await fetchChartData(selectedItem._id, idToken);

        if (response && response.data && response.data.owner) {
          const {categoryCounts, percentages} = response.data.owner;

          if (categoryCounts && Object.keys(categoryCounts).length > 0) {
            const categories = Object.keys(categoryCounts);
            const seriesData = Object.values(categoryCounts);

            setChartOptions((options) => ({
              ...options,
              xaxis: {
                categories: categories,
              },
            }));
            setChartSeries([{data: seriesData}]);
          }

          if (percentages) {
            setPieChart((pieChart) => ({
              ...pieChart,
              series: [
                parseFloat(percentages.under24h),
                parseFloat(percentages.from24hTo48h),
                parseFloat(percentages.over48h),
              ],
            }));
          }
        }
      } catch (error) {
       
      }
    };

    if (idToken && selectedItem && selectedItem._id) {
      fetchData(pagination.current, pagination.pageSize);
      fetchChartDataAsync();
    }
  }, [idToken, selectedItem, pagination.current, pagination.pageSize]);

  const fetchTableData = async (idToken, assetIds, page = 1, limit = 10) => {
    const config = {
      headers: {
        "auth-token": idToken,
      },
      params: {
        property_code: selectedProperty?.Property_Code,
        page: page,
        limit: limit,
      },
    };

    try {
      const response = await axios.get(Endpoint.Maintenance_Table, config);
      return response.data;
    } catch (error) {
  
      return null;
    }
  };

  const fetchChartData = async (assetId, idToken) => {
    try {
      const response = await axios.get(Endpoint.getallownerdata, {
        headers: {
          "auth-token": idToken,
        },
        params: {
          assetId: assetId,
        },
      });
      return response.data;
    } catch (error) {
 
      return null;
    }
  };

  const columns = [
    {
      title: "Unit",
      dataIndex: "Unit",
    },
    {
      title: "Category",
      dataIndex: "Category",
    },
    {
      title: "Description",
      dataIndex: "Description",
    },
    {
      title: "Open Duration",
      dataIndex: "OpenDuration",
    },
    {
      title: "Date Entered",
      dataIndex: "DateEntered",
    },
  ];

  const handleEditClick = (title) => {
    setEditPopupTitle(title);
    setShowEditPopup(true);
  };

  const handleCloseEditPopup = () => {
    setShowEditPopup(false);
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  return (
    <div>
      <div className="Edit_Maintenance">
        {hasMaintenancePermission && (
          <>
            <button
              className="edit-button"
              onClick={() => handleEditClick("Unit Comparison")}
            >
              Edit
            </button>
          </>
        )}
      </div>
      <div className="portfolioData-maintenance-card">
        {loading ? (
          <LoaderOverlay desc="Loading..." />
        ) : (
          <>
            <div className="charts-container">
              <div className="portfolioData-maintenance-column-chart">
                <h4>SERVICE REQUESTS BY CATEGORY</h4>
                <ReactApexChart
                  options={chartOptions}
                  series={chartSeries}
                  type="bar"
                  height={350}
                />
              </div>
              <div className="portfolioData-maintenance-donut-chart">
                <h4>OPEN DURATION BREAKDOWN</h4>
                <ReactApexChart
                  options={pieChart}
                  series={pieChart.series}
                  type="donut"
                  width={380}
                  height={"100%"}
                />
              </div>
            </div>
            <div className="maintenance-table">
              <h4>Maintenance Work Orders</h4>

              <div className="maintenance_table_wrapper">
                <Table
                  columns={columns}
                  dataSource={tableData}
                  pagination={{
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: pagination.total,
                    showSizeChanger: true,
                  }}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Maintenance;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  taskSeleted: {},
  tasks: []
};

const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    setSelectedDate: (state, action) => {
      state.taskSeleted = action.payload; 
    },
    setTasks: (state, action) => {
      state.tasks = action.payload;
    }
  }
});

export const { setSelectedDate, setTasks } = calendarSlice.actions;
export default calendarSlice.reducer;

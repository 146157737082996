import React from "react";
import "./checkbox.scss";
import unchecked from "../../../assets/svg/unchecked.svg";
import ischecked from "../../../assets/svg/checked.svg";

function Checkbox({ label, variant, img, label1, label2, checked, onChange }) {
  return (
    <label className={`checkbox ${variant === 2 ? "variant_2" : ""}`}>
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      {!checked ? (
        <img
          src={unchecked}
          alt="unchecked"
          style={{ height: "18px", width: "18px" }}
        />
      ) : (
        <img
          style={{ height: "18px", width: "18px" }}
          src={ischecked}
          alt="checked"
        />
      )}
      {label}
      {variant === 2 && (
        <div className="variant_2">
          <img
            src={img}
            alt="image"
          />
          <div>
            <p>{label1}</p>
            <span>{label2}</span>
          </div>
        </div>
      )}
    </label>
  );
}

export default Checkbox;

import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import Endpoint from "../../utils/Endpoint";

const initialState = {
  notifications: [],
  loading: false,
  error: null,
  newNotifications: 0,
};

export const fetchNotifications = createAsyncThunk("notifications/fetchNotifications", async (_, {getState, rejectWithValue}) => {
  const {auth} = getState();
  const idToken = auth.idToken;
  const config = {
    headers: {
      "auth-token": idToken,
    },
  };
  try {
    const response = await axios.get(Endpoint.getNotification, config);
    return response.data.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const markNotificationsAsRead = createAsyncThunk("notifications/markNotificationsAsRead", async (notificationIds, {getState, rejectWithValue, dispatch}) => {
  const {auth} = getState();
  const idToken = auth.idToken;
  const config = {
    headers: {
      "auth-token": idToken,
    },
  };

  try {
    await axios.post(`${Endpoint.readNotnotification}/${notificationIds}`, {notificationIds}, config);
    dispatch(markNotificationsAsReadOptimistic(notificationIds));
    return {notificationIds};
  } catch (error) {
    if (error.response && error.response.data) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue("An error occurred while marking notifications as read.");
    }
  }
});

const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    markNotificationsAsReadOptimistic(state, action) {
      state.notifications = state.notifications.map((notification) => ({
        ...notification,
        read: action.payload.includes(notification._id) ? true : notification.read,
      }));
      state.newNotifications = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotifications.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.notifications = action.payload;
        state.newNotifications = action.payload.filter((notification) => !notification.read).length;
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(markNotificationsAsRead.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(markNotificationsAsRead.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(markNotificationsAsRead.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {markNotificationsAsReadOptimistic} = notificationSlice.actions;
export const selectNotifications = (state) => state.notifications;

export default notificationSlice.reducer;


import {PrimaryButton} from "../../components/reusableComponents/Buttons/Buttons";
import overviewRight from "../../assets/svg/overviewRight.svg";
import "./overview.scss";
import React, {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {handleLogout} from "../../utils/Signout";
import {checkToken} from "../../utils/Axios";import Timeout from "../../components/Autologout/Timeout";

const Overview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {idToken} = useSelector((state) => state.auth);
  Timeout(idToken);

  useEffect(() => {
    const validateToken = async () => {
      const isValid = await checkToken();
      if (!isValid) {
        handleLogout(dispatch, navigate);
      }
    };

    if (idToken) {
      validateToken();
    }
  }, [idToken, dispatch, navigate]);

  return (
    <div className="overview">
      <div className="overview_content">
        <div className="left">
          <h1>
            Welcome to <br /> Lilypads Investor Portal
          </h1>
          <p>
            Lilypads is an AI-powered platform that empowers owners, investors, asset managers, and operators to maximize revenue by leveraging existing data to increase transactional efficiency and
            optimize property performance.
          </p>
          <Link to={idToken ? "/portfolio" : "signup"}>
            <PrimaryButton name={"Get Started"} />
          </Link>
        </div>
        <div className="right">
          <img
            src={overviewRight}
            alt="Overview Right"
          />
        </div>
      </div>
    </div>
  );
};

export default Overview;

import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import axios from "axios";
import {Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField, Typography, CircularProgress} from "@mui/material";
import {returnMessages} from "../../../store/reducers/message";
import {returnErrors} from "../../../store/reducers/error";
import {fetchInvestmentDetails} from "../../../store/reducers/investment/investmentSlice";
import Endpoint from "../../../utils/Endpoint";

// PaymentValueDialog Component
export const PaymentValueDialog = ({open, onClose, investmentData}) => {
  const dispatch = useDispatch();
  const payment = useSelector((state) => state.investmentSlice?.payment);
  const status = useSelector((state) => state.investmentSlice?.status);
  const error = useSelector((state) => state.investmentSlice?.error);
  const idToken = useSelector((state) => state.auth.idToken);
  const [paymentValue, setPaymentValue] = useState("");
  const ReadyToInvest_id = investmentData?._id;

  useEffect(() => {
    if (!open || !ReadyToInvest_id) return;

    dispatch(fetchInvestmentDetails(ReadyToInvest_id));
  }, [open, ReadyToInvest_id, dispatch]);

  const handlePaymentValueChange = (e) => {
    setPaymentValue(e.target.value);
  };

  const handleDoneClick = async () => {
    if (!paymentValue) {
      alert("Please enter a payment value");
      return;
    }

    try {
      const response = await axios.post(
        Endpoint.storepaymentdetails,
        {paymentValue},
        {
          params: {investmentId: ReadyToInvest_id},
          headers: {"auth-token": idToken},
        },
      );

      if (response.data.success) {
        dispatch(returnMessages(`Success`, 200));
        onClose();
      }
    } catch (error) {
      dispatch(returnErrors("errorMessage"));
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Investment Details</DialogTitle>
      <DialogContent>
        {status === "loading" ? (
          <Typography>Loading...</Typography>
        ) : status === "failed" ? (
          <Typography>No Information Added</Typography>
        ) : payment ? (
          <div style={{marginBottom: "20px"}}>
            <Typography gutterBottom>
              Payment Proof: <strong>{payment.username}</strong>
            </Typography>
            {payment.imageUrl && (
              <div style={{textAlign: "center", marginBottom: "15px"}}>
                <img
                  src={payment.imageUrl}
                  className="Payments_image"
                  alt="Investment Proof"
                  style={{
                    maxWidth: "100%",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    border: "1px solid #ddd",
                  }}
                />
              </div>
            )}
            <TextField
              label="Amount"
              type="number"
              variant="outlined"
              fullWidth
              margin="normal"
              value={paymentValue}
              onChange={handlePaymentValueChange}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleDoneClick}
              style={{marginTop: "10px"}}
            >
              Done
            </Button>
          </div>
        ) : (
          <Typography>Loading...</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

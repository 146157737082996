import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Button} from "@mui/material";
import DropDown from "../../components/reusableComponents/Dropdown/Dropdown";
import Checkbox from "../profile/settings/Checkbox";
import DeleteIcon from "../../assets/portfolio/svgs/delete.svg";
import plusIcon from "../../assets/portfolio/svgs/plus.svg";
import "./Disposition.scss";
import axios from "axios";
import {useSelector} from "react-redux";
import {fetchPortfolio, selectProperty, updateProperty} from "../../store/reducers/portfolioSlice.js";
import { useDispatch } from "react-redux";
import Endpoint from "../../utils/Endpoint.js";
import useAuthConfig from "../../utils/Config.js";

export const UtilitiesArrangement = () => {
  const {selectedProperty} = useSelector((state) => state.portfolio);
  const {idToken} = useSelector((state) => state.auth);
  let dispatch = useDispatch();
  var config = useAuthConfig();

  const [openDialog, setOpenDialog] = useState(false);
  const [newUtility, setNewUtility] = useState({
    utility: "",
    servicer: "",
    paidBy: "",
    meterType: "",
  });

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleInputChange = (e) => {
    const {name, value} = e.target;
    setNewUtility({...newUtility, [name]: value});
  };



  const handleAddUtility = () => {
    axios
      .post(`${Endpoint.addUtility}/${selectedProperty._id}/utilities`, newUtility, config)
      .then((response) => {
        dispatch(
          updateProperty({
            propertyId: selectedProperty._id,
            updatedData: response.data,
            selectedItem: selectedProperty,
          }),
        );
        handleClose();
      })
      .catch((error) => {
       
      });
  };

 

  return (
    <div className="utility-arrangement-container">
      <div className="arrangement-table-header-UtilitiesArrangement">
        <h4>#</h4>
        <h4>UTILITY</h4>
        <h4>SERVICER</h4>
        <h4>PAID BY</h4>
        <h4>METER TYPE</h4>
      </div>

      <div className="arrangement-table-content">
        {selectedProperty?.utilities?.map((utility, idx) => (
          <div
            key={idx}
            className="utility-row"
          >
            <p>{idx + 1}</p>
            <p>{utility.utility}</p>
            <p>{utility.servicer}</p>
            <p>{utility.paidBy}</p>
            <p>{utility.meterType}</p>
          </div>
        ))}
      </div>
      <Link
        className="utility-button"
        onClick={handleClickOpen}
      >
        <img
          src={plusIcon}
          alt="Contact icon"
        />
        <h4>Add Utility</h4>
      </Link>

      <Checkbox label="I want my Utility Information to be uploaded into Asset Discovery" />

      <Dialog
        open={openDialog}
        onClose={handleClose}
      >
        <DialogTitle>Add New Utility</DialogTitle>
        <DialogContent>
          <DialogContentText>Please fill in the details to add a new utility.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Utility"
            name="utility"
            value={newUtility.utility}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Servicer"
            name="servicer"
            value={newUtility.servicer}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Paid By"
            name="paidBy"
            value={newUtility.paidBy}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Meter Type"
            name="meterType"
            value={newUtility.meterType}
            onChange={handleInputChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleAddUtility}
            color="primary"
          >
            Add Utility
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

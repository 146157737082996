import React, {useState, useEffect, useCallback} from "react";
import ChatLeft from "./ChatLeft";
import "./LilypadsAI.scss";
import {useSelector, useDispatch} from "react-redux";
import {fetchUserDetails, setAssetId, setAssetName} from "../../store/reducers/userDetailsSlice";
import {fetchPortfolio, selectProperty} from "../../store/reducers/portfolioSlice";
import {items} from "../portfolio/portfolioData/itemsData";
import SearchDarkIcon from "../../assets/portfolio/svgs/search-dark.svg";
import {ReactComponent as InviteEmpBtn} from "../../assets/chatCompAssets/icons/inviteEmpBtn.svg";
import noimage from "../../assets/portfolio/images/add_3342713.png";
import {fetchAssetsByAdminEmail} from "../../store/reducers/AdminGivenAccess";
import Timeout from "../../components/Autologout/Timeout";
import {Link} from "react-router-dom";
import {ReactComponent as PlusIcon} from "../../assets/Openai/plus_icon.svg";
import {fetchChats, startNewChat, addMessageToChat, fetchMessagesForChat, clearChats} from "../../store/reducers/OpenAIChat/OpenAICHAT";
import {CircularProgress} from "@mui/material";
import {ReactComponent as ExpandIconBtn} from "../../assets/chatCompAssets/icons/expandBtnIcon.svg";
import {setReadToInvest} from "../../store/reducers/userDetailsSlice";
import {useNavigate} from "react-router-dom";
import InviteacolleaguetoAsset from "./Popup/InviteacolleaguetoAsset";

const LilypadsAI = () => {
  const [isClicked, setIsClicked] = useState(1);
  const [showDashboard, setShowDashboard] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const dispatch = useDispatch();
  const {userInformation} = useSelector((state) => state.auth);
  const {AssectDataOwnerOperator, loading, error, selectedProperty} = useSelector((state) => state.portfolio);
  const {refreshToken} = useSelector((state) => state.auth);
  const {idToken} = useSelector((state) => state.auth);
  const [navIndex, setNavIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  Timeout(idToken);
  const [selectedChat, setSelectedChat] = useState(null);
  let userselectedProperty = useSelector((state) => state?.portfolio?.selectedProperty);
  let History_from = useSelector((state) => state.openaimessages);
  const [isAnimating, setIsAnimating] = useState(false);
  const filteredAssets = AssectDataOwnerOperator?.filter((item) => item?.assetName?.toLowerCase().includes(searchQuery.toLowerCase()));
  const [creatingNewChat, setCreatingNewChat] = useState(false);
  const navigate = useNavigate();
  const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
  const [sideBarVisible, setSideBarVisible] = useState(true);

  // get all property

  useEffect(() => {
    if (refreshToken) {
      dispatch(fetchUserDetails(refreshToken));
    }
  }, [dispatch, refreshToken]);

  useEffect(() => {
    if (userInformation?.email) {
      dispatch(fetchPortfolio(userInformation?.email))
        .unwrap()
        .catch((error) => {});
      dispatch(fetchAssetsByAdminEmail({idToken, email: userInformation?.email}));
    }
  }, [dispatch, userInformation?.email]);

  const toggleSidebar = useCallback(() => {
    setSidebarOpen((prevSidebarOpen) => !prevSidebarOpen);
  }, []);

  // get chat

  useEffect(() => {
    dispatch(fetchChats({userId: userInformation?._id, assetId: selectProperty?._id, idToken: idToken}));
  }, [dispatch]);

  // start chat -
  const handleNewChat = async () => {
    const tempChatId = `temp-${Date.now()}`;
    setIsAnimating(true);
    setCreatingNewChat(true);

    const tempChat = {
      id: tempChatId,
      firstMessageSummary: "Starting new chat...",
      createdAt: new Date().toISOString(),
      isTemporary: true,
      messages: [],
    };

    setSelectedChat(tempChat);
    setIsClicked(1);
    dispatch(addMessageToChat({chatId: tempChatId, message: "Loading..."}));

    try {
      const newChat = await dispatch(
        startNewChat({
          userId: userInformation._id,
          assetId: userselectedProperty._id,
          propertyCode: userselectedProperty.Property_Code,
          idToken,
        }),
      ).unwrap();

      await dispatch(
        fetchChats({
          userId: userInformation._id,
          assetId: userselectedProperty._id,
          idToken,
        }),
      ).unwrap();

      const messages = await dispatch(
        fetchMessagesForChat({
          ChatId: newChat.id,
          asset_id: userselectedProperty._id,
          idToken,
        }),
      ).unwrap();

      setSelectedChat({
        ...newChat,
        messages: messages.messages || [],
      });
    } catch (error) {
    } finally {
      setIsAnimating(false);
      setCreatingNewChat(false);
    }
  };

  // change property - chat

  const handlePropertyClick = useCallback(
    async (item) => {
      if (!userInformation || !userInformation._id) {
        return;
      }

      dispatch(clearChats());
      dispatch(selectProperty(item));
      setShowDashboard(false);
      dispatch(setAssetId(item?._id));
      dispatch(setAssetName(item?.assetName));

      try {
        const chats = await dispatch(
          fetchChats({
            userId: userInformation._id,
            assetId: item._id,
            idToken,
          }),
        ).unwrap();

        if (chats?.length > 0) {
          const sortedChats = [...chats].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
          setSelectedChat(sortedChats[0]);
          setIsClicked(1);
          handleChatClick(sortedChats[0]);
        } else {
          // Logic from handleNewChat
          const tempChatId = `temp-${Date.now()}`;
          setIsAnimating(true);
          setCreatingNewChat(true);

          const tempChat = {
            id: tempChatId,
            firstMessageSummary: "Starting new chat...",
            createdAt: new Date().toISOString(),
            isTemporary: true,
            messages: [],
          };

          setSelectedChat(tempChat);
          setIsClicked(1);
          dispatch(addMessageToChat({chatId: tempChatId, message: "Loading..."}));

          try {
            const newChat = await dispatch(
              startNewChat({
                userId: userInformation._id,
                assetId: item._id,
                propertyCode: item.Property_Code,
                idToken,
              }),
            ).unwrap();

            await dispatch(
              fetchChats({
                userId: userInformation._id,
                assetId: item._id,
                idToken,
              }),
            ).unwrap();

            const messages = await dispatch(
              fetchMessagesForChat({
                ChatId: newChat.id,
                asset_id: item._id,
                idToken,
              }),
            ).unwrap();

            setSelectedChat({
              ...newChat,
              messages: messages.messages || [],
            });
          } catch (error) {
            console.error("Error starting new chat:", error);
          } finally {
            setIsAnimating(false);
            setCreatingNewChat(false);
          }
        }
      } catch (error) {
        const tempChatId = `temp-${Date.now()}`;
        setIsAnimating(true);
        setCreatingNewChat(true);

        const tempChat = {
          id: tempChatId,
          firstMessageSummary: "Starting new chat...",
          createdAt: new Date().toISOString(),
          isTemporary: true,
          messages: [],
        };

        setSelectedChat(tempChat);
        setIsClicked(1);
        dispatch(addMessageToChat({chatId: tempChatId, message: "Loading..."}));

        try {
          const newChat = await dispatch(
            startNewChat({
              userId: userInformation._id,
              assetId: item._id,
              propertyCode: item.Property_Code,
              idToken,
            }),
          ).unwrap();

          await dispatch(
            fetchChats({
              userId: userInformation._id,
              assetId: item._id,
              idToken,
            }),
          ).unwrap();

          const messages = await dispatch(
            fetchMessagesForChat({
              ChatId: newChat.id,
              asset_id: item._id,
              idToken,
            }),
          ).unwrap();

          setSelectedChat({
            ...newChat,
            messages: messages.messages || [],
          });
        } catch (error) {
        } finally {
          setIsAnimating(false);
          setCreatingNewChat(false);
        }
      }
    },
    [dispatch, userInformation, idToken, handleNewChat],
  );

  // Get All chat ----------------------------------

  // Select Latest chat -

  useEffect(() => {
    if (idToken && userInformation?._id && selectedProperty?._id) {
      dispatch(
        fetchChats({
          userId: userInformation._id,
          assetId: selectedProperty._id,
          idToken,
        }),
      )
        .unwrap()
        .then((chats) => {
          if (chats?.length > 0 && !creatingNewChat) {
            const sortedChats = [...chats].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

            setTimeout(() => {
              setSelectedChat(sortedChats[0]);
              setIsClicked(1);
              handleChatClick(sortedChats[0]);
            }, 500);
          }
        })
        .catch((error) => {
          console.error("Error fetching chats:", error);
        });
    }
  }, [dispatch, idToken, userInformation?._id, selectedProperty?._id, creatingNewChat]);

  // Get Messages Data ------------------------------------------------------

  const handleChatClick = (chat) => {
    setSelectedChat(chat);
    setIsClicked(1);

    dispatch(fetchMessagesForChat({ChatId: chat?.chatId, asset_id: selectedProperty?._id, idToken}))
      .unwrap()
      .then((response) => {
        setSelectedChat((prevChat) => ({
          ...prevChat,
          messages: response.messages || [],
        }));
      })
      .catch((error) => {});
  };

  const handleNewMessage = (chatId, newMessage) => {
    setSelectedChat((prev) => {
      if (prev && prev._id === chatId) {
        return {
          ...prev,
          messages: [...(prev.messages || []), newMessage],
        };
      }
      return prev;
    });

    dispatch(addMessageToChat({chatId, message: newMessage}));
  };

  useEffect(() => {
    const centerWrapper = document.querySelector(".center-wrapper");

    if (centerWrapper) {
      if (!sideBarVisible) {
        centerWrapper.classList.add("chatContainerEnvironment");
      } else {
        centerWrapper.classList.remove("chatContainerEnvironment");
      }
    }
  }, [sideBarVisible]);

  const handleSelectAndNavigate = () => {
    dispatch(setReadToInvest(true));
    navigate(`/${selectedProperty?._id}/dealsroom`);
  };

  return (
    <div>
      <div className="deals-room">
        {isClicked !== 20 && (
          <div className={`StandAloneBeartacticAI_deals-room-left-container ${sideBarVisible ? "visible" : "notVisible"}`}>
            <div className="Start_NewChat">
              <button
                className="newChatBtn"
                onClick={handleNewChat}
                disabled={isAnimating}
              >
                {isAnimating ? (
                  <CircularProgress
                    size={20}
                    style={{color: "white"}}
                  />
                ) : sideBarVisible ? (
                  <>
                    <PlusIcon /> New Chat
                  </>
                ) : (
                  <PlusIcon />
                )}
              </button>
              {/* <button className="manageReportsBtn">Manage Reports</button> */}
              <button
                className="expandButton"
                onClick={() => setSideBarVisible((prev) => !prev)}
              >
                <ExpandIconBtn />
              </button>
            </div>

            <div className="StandAloneBeartacticAI_portfolio-container">
              <div className={`sidebar ${sidebarOpen ? "open" : ""}`}>
                <Link
                  className="sidebar-header"
                  to={`/${userInformation?._id}/portfolio`}
                >
                  <div className="image-gallery">
                    {selectedProperty?.assetsImages && selectedProperty?.assetsImages.length > 0 ? (
                      selectedProperty?.assetsImages.slice(0, 4).map((image, index) => (
                        <img
                          key={index}
                          src={image}
                          alt={`Property Image ${index + 1}`}
                          className="gallery-image"
                        />
                      ))
                    ) : (
                      <img
                        src={noimage}
                        alt="No Image"
                        className="gallery-image"
                      />
                    )}
                  </div>

                  <div className="sideheader-textWrapper">
                    <h2>Portfolio Overview</h2>
                    <h4>
                      <span>{AssectDataOwnerOperator?.length}</span> Properties
                    </h4>
                  </div>
                </Link>

                <div className="StandAloneBeartacticAI_search-input-wrapper">
                  <input
                    type="text"
                    placeholder="Type to search..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />

                  <div className="search-icons">
                    <img
                      src={SearchDarkIcon}
                      alt="img"
                    />
                  </div>
                </div>

                <div className="StandAloneBeartacticAI_portfolio-container">
                  <div className={`sidebar ${sidebarOpen ? "open" : ""}`}>
                    <div className="StandAloneBeartacticAI_sidebar-content">
                      {loading ? (
                        <div className="shimmer-effect">
                          {[...Array(6)]?.map((_, index) => (
                            <div
                              key={index}
                              className="shimmer-block"
                            ></div>
                          ))}
                        </div>
                      ) : (
                        filteredAssets?.map((item) => (
                          <div
                            className={`nav-card ${selectedProperty?._id === item?._id ? "selected" : ""}`}
                            key={item._id}
                            onClick={() => handlePropertyClick(item)}
                          >
                            <img
                              src={item?.assetsImages && item?.assetsImages?.length > 0 ? item?.assetsImages[0] : noimage}
                              alt={item?.assetName || "Default Alt Text"}
                              loading="lazy"
                            />
                            <div className="nav-card-info">
                              <h2>{item?.assetName}</h2>
                              <p>{item?.assetsAddress}</p>
                              <h4>{item?.addedBy}</h4>
                              <div className="price-wrapper">
                                <div className="status-wrapper">
                                  <h4>${item?.assetValue?.toLocaleString()}</h4>
                                  {selectedProperty?._id === item?._id && (
                                    <button
                                      className="inviteEmpBtn"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setIsInviteDialogOpen((prev) => !prev);
                                      }}
                                    >
                                      <InviteEmpBtn />
                                    </button>
                                  )}
                                </div>
                                {(item?.status === "For Sale" || item?.status === "For Rent") && (
                                  <h2>
                                    {item?.currency} {item?.pricePerUnit}
                                  </h2>
                                )}
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                  <div
                    className="hamburger-icon"
                    onClick={toggleSidebar}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
              <div
                className="hamburger-icon"
                onClick={toggleSidebar}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>

            {/* History */}
            <div className="Insight_History_Container">
              <h1>Insight History</h1>
              <div className="Insight_History">
                {loading ? (
                  <div className="loading-container">
                    <CircularProgress />
                  </div>
                ) : History_from.chats.length === 0 && !selectedChat?.isTemporary ? (
                  <div className="no-history-container">
                    <p>No chat history found</p>
                  </div>
                ) : (
                  <ul>
                    {[...(selectedChat?.isTemporary ? [selectedChat] : []), ...History_from.chats]
                      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                      .map((chat, index) => (
                        <li
                          key={chat.id || `temp-${Date.now()}`}
                          onClick={() => handleChatClick(chat)}
                          className={selectedChat?.id === chat.id ? "active-chat" : ""}
                        >
                          <p className="Summary_StandAloneBeartacticAI">{chat.isTemporary ? "new chat" : chat.firstMessageSummary}</p>
                          <span className="Date_StandAloneBeartacticAI">{new Date(chat.createdAt).toLocaleString()}</span>
                        </li>
                      ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        )}

        <div className="center-wrapper">
          {isClicked === 1 ? (
            selectedChat?.messages?.length > 0 ? (
              <ChatLeft
                selectedChat={selectedChat}
                onNewMessage={handleNewMessage}
                setSelectedChat={setSelectedChat}
                setIsClicked={setIsClicked}
              />
            ) : (
              <div className="loading-wrapper">
                <CircularProgress />
              </div>
            )
          ) : (
            <div className="no-chat-selected">
              <p>Select a property or start a new chat to begin</p>
            </div>
          )}
        </div>
      </div>
      {isInviteDialogOpen && (
        <InviteacolleaguetoAsset
          open={isInviteDialogOpen}
          onClose={() => setIsInviteDialogOpen(false)}
          // fetchStatuses={fetchStatuses}
        />
      )}
    </div>
  );
};

export default LilypadsAI;

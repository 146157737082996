import React, {useState, useEffect} from "react";
import "./AssetCard.scss";
import dropbtn from "../../assets/acqusition/svg/dropbtn.svg";
import location from "../../assets/acqusition/svg/location.svg";
import calender from "../../assets/acqusition/svg/calender.svg";
import profile from "../../assets/acqusition/svg/profile.svg";
import mobile from "../../assets/acqusition/svg/mobile.svg";
import link from "../../assets/acqusition/svg/link.svg";
import redirect from "../../assets/acqusition/svg/redirect.svg";
import blocher from "../../assets/acqusition/svg/blocher.svg";
import {Link, useNavigate} from "react-router-dom";
import moment from "moment";
import {useSelector, useDispatch} from "react-redux";
import axios from "axios";
import {returnMessages} from "../../store/reducers/message";
import {returnErrors} from "../../store/reducers/error";
import {ReadToInvest} from "./DealsPopUp/ReadToInvest";
import {PaymentValueDialog} from "./DealsPopUp/PaymentValueDialog";
import {InvestmentDialog} from "./DealsPopUp/InvestmentDialog";
import Send_Contarct from "./DealsPopUp/Upload_contact";
import PDF_Viewer from "./DealsPopUp/PDF_Viewer";
import bellIcon from "../../assets/notification-bell_9187467.svg";
import useAuthConfig from "../../utils/Config";
import Endpoint from "../../utils/Endpoint";

const AssetCardForPortfolio = ({selectedData}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [investmentCreated, setInvestmentCreated] = useState(false);
  const [investmentData, setInvestmentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openReadToInvest, setOpenReadToInvest] = useState(false);
  const [openInvestmentDialog, setOpenInvestmentDialog] = useState(false);
  const [submitProofPopup, setSubmitProofPopup] = useState(false);
  const [iban, setIban] = useState("");
  const [bic, setBic] = useState("");
  // const id = selectedData?._id;
  const roleType = useSelector((state) => state.auth.userInformation.roleTypes);
  const {idToken} = useSelector((state) => state.auth);
  const [investmentShared, setInvestmentShared] = useState(false);
  const [openPaymentValueDialog, setOpenPaymentValueDialog] = useState(false);
  const [upload_pdf, setUpload_pdf] = useState(false);
  const [view_pdf, setView_pdf] = useState(false);
  const handleReadToInvestClick = () => setOpenReadToInvest(true);
  const handleInvestmentDialogClick = () => setOpenInvestmentDialog(true);
  const handleAddInvestmentClick = () => setOpenPaymentValueDialog(true);
  const Upload_Pdf = () => setUpload_pdf(true);
  const View_pdf = () => setView_pdf(true);
  const [pdfPreview, setPdfPreview] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [editedPdfUrl, setEditedPdfUrl] = useState(null);
  const {userInformation} = useSelector((state) => state.userInfo);
  const [GetPaymentIfPaid, setGetPaymentIfPaid] = useState("");
  const handleBellClickicon = () => setShowDropdown(!showDropdown);
  const [getpdfurldata, setgetpdfurldata] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const config = useAuthConfig();

  const handleFileChange = (file) => {
    const fileUrl = URL.createObjectURL(file);
    setPdfPreview(fileUrl);
  };

  // get editpdf

  useEffect(() => {
    const fetchEditedPdf = async () => {
      try {
        const response = await axios.get(`${Endpoint.get_editedPDF}?assetId=${selectedData?._id}`, {
          headers: {
            "auth-token": idToken,
          },
        });

        setEditedPdfUrl(response?.data?.data?.url);
      } catch (error) {
        console.error("Error fetching edited PDF:", error);
      }
    };

    if (selectedData?._id) {
      fetchEditedPdf();
    }
  }, [selectedData, idToken]);

  useEffect(() => {
    const fetchInvestmentData = async () => {
      try {
        const response = await axios.get(Endpoint.InvestNow, {
          params: {assetid: selectedData?._id},
          headers: {
            "auth-token": idToken,
          },
        });
        setInvestmentData(response.data.data);
        setInvestmentCreated(response.data.data.length > 0);
      } catch (err) {
        setError(err.response?.data?.message || "Error fetching investment data");
      } finally {
        setLoading(false);
      }
    };

    if (selectedData?._id) {
      fetchInvestmentData();
    }
  }, [selectedData?._id, idToken]);

  const handleDialogClose = (dialogType) => {
    switch (dialogType) {
      case "readToInvest":
        setOpenReadToInvest(false);
        break;
      case "investmentDialog":
        setOpenInvestmentDialog(false);
        break;
      case "submitProof":
        setSubmitProofPopup(false);
        break;
      case "paymentValue":
        setOpenPaymentValueDialog(false);
        break;
      case "Send_Contract":
        setUpload_pdf(false);
        break;
      case "View_pdf":
        setView_pdf(false);
        break;
      default:
        break;
    }
  };

  const handleInvestment = async () => {
    try {
      const config = {
        headers: {
          "auth-token": idToken,
        },
        params: {
          assetid: selectedData?._id,
        },
      };
      let is_ready = true;
      const response = await axios.post(Endpoint.Add_InvestNow, {is_ready, iban, bic}, config);
      if (response.data.success) {
        setInvestmentCreated(true);
        dispatch(returnMessages(`Success`, 200));
      }
    } catch (error) {
      setError(error.response?.data?.message || "Error assigning assets");
      dispatch(returnErrors("errorMessage"));
    }
  };

  useEffect(() => {
    const fetchInvestmentStatus = async () => {
      try {
        const response = await axios.get(Endpoint.GetInvestmentStatus, {
          params: {assetid: selectedData?._id},
          headers: {
            "auth-token": idToken,
          },
        });

        if (response.data.success) {
          setInvestmentCreated(response.data.isReady);
          setInvestmentShared(response.data.isShared);
        } else {
          setError(response.data.message);
        }
      } catch (err) {
        setError(err.response?.data?.message || "Error fetching investment status");
      }
    };

    if (selectedData?._id) {
      fetchInvestmentStatus();
    }
  }, [selectedData?._id, idToken]);

  // GET PDF
  useEffect(() => {
    const fetchPdf = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${Endpoint.get_pdf}?assetId=${selectedData?._id}&userId=${userInformation?._id} `, config);
        setPdfUrl(response?.data?.url);
      } catch (error) {
        console.error("Failed to fetch PDF:", error);
      } finally {
        setLoading(false);
      }
    };

    if (selectedData?._id) {
      fetchPdf();
    }
  }, [selectedData, idToken]);

  const handlePaymentValueSubmit = async (paymentValue) => {
    try {
      const config = {
        headers: {
          "auth-token": idToken,
        },
        params: {
          assetid: selectedData?._id,
        },
      };

      const response = await axios.post(Endpoint.Upload_PaymentProof, {paymentValue}, config);

      if (response.data.success) {
        dispatch(returnMessages("Payment value submitted successfully", 200));
        setInvestmentCreated(true);
        setOpenPaymentValueDialog(false);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || "Error submitting payment value";
      setError(errorMessage);
      dispatch(returnErrors(errorMessage));
    }
  };

  // get Payment Data
  useEffect(() => {
    const fetchInvestmentStatus = async () => {
      try {
        const response = await axios.get(Endpoint.get_paymentsDetails, {
          params: {ReadyToInvest_id: investmentData._id},
          headers: {
            "auth-token": idToken,
          },
        });

        if (response.data.success) {
          setGetPaymentIfPaid(response.data.data);
        } else {
          setError(response.data.message);
        }
      } catch (err) {
        setError(err.response?.data?.message || "Error fetching payment status");
      }
    };

    if (investmentData?._id) {
      fetchInvestmentStatus();
    }
  }, [investmentData, idToken]);

  // Upload Documets
  const handleBellClick = () => {
    setShowDropdown((prev) => !prev);
  };

  return (
    <div className="asset-card">
      <div className="asset-card-inner">
        <div className="contact-head">
          <span>Contacts</span>
          <div>
            {userInformation?.roleTypes === 2 && (
              <div className="pdf-bell-container">
                <img
                  src={bellIcon}
                  alt="Bell icon"
                  className="pdf-bell-icon"
                  onClick={handleBellClickicon}
                />

                {showDropdown && (
                  <div className="pdf-dropdown-menu">
                    {editedPdfUrl ? (
                      <div
                        className="pdf-dropdown-item"
                        onClick={() => window.open(editedPdfUrl, "_blank")}
                      >
                        <span>PDF Document</span>
                      </div>
                    ) : (
                      <span className="pdf-no-file">No edited PDF available</span>
                    )}
                  </div>
                )}
              </div>
            )}
            <img
              src={dropbtn}
              alt="Dropdown button"
            />
          </div>
        </div>

        {/* box details */}
        <div className="card-AssetCard">
          {selectedData?.assetName && (
            <div className="asset-heading">
              <span>{selectedData?.assetName}</span>
            </div>
          )}

          <div className="rp-section-heading">
            <span>{selectedData?.assetValue.toLocaleString("en-US", {style: "currency", currency: "USD"})}</span>
          </div>

          {selectedData?.assetsAddress && (
            <div className="location-section">
              <img
                src={location}
                alt="Location icon"
                className="Location_icon"
              />
              <div>
                <span>{selectedData?.assetsAddress}</span>
              </div>
            </div>
          )}

          {selectedData?.date && (
            <div className="flex calender-section">
              <img
                src={calender}
                alt="Calendar icon"
                className="Calendar_icon"
              />
              <div>
                <span>{moment(selectedData?.date).format("MMMM Do YYYY, h:mm:ss a")}</span>
              </div>
            </div>
          )}
        </div>

        {/* <hr /> */}

        {(selectedData?.addedBy || selectedData?.offers) && (
          <div className="flex profile-section">
            <img
              src={profile}
              alt="Profile icon"
            />
            <div>
              {selectedData?.addedBy && <span>Added by {selectedData?.addedBy}</span>}
              {selectedData?.offers && <span>{selectedData.offers} offers</span>}
            </div>
          </div>
        )}

        {selectedData?.contactPhone && (
          <div className="flex mob-section">
            <img
              src={mobile}
              alt="Mobile icon"
            />
            <div style={{display: "flex", flexDirection: "column", gap: "5px"}}>{selectedData?.contactPhone}</div>
          </div>
        )}

        {selectedData?.contactWebsite && (
          <div className="flex contact-detail">
            <img
              src={link}
              alt="Link icon"
            />
            <span>{selectedData?.contactWebsite}</span>
            <img
              src={redirect}
              alt="Redirect icon"
            />
          </div>
        )}

        {selectedData?.contactBrochure && (
          <div className="flex contact-detail">
            <img
              src={blocher}
              alt="Brochure icon"
            />
            <span>{selectedData?.contactBrochure}</span>
            <img
              src={redirect}
              alt="Redirect icon"
            />
          </div>
        )}
      </div>

      {roleType === 2 && !investmentShared && !investmentCreated && (
        <div
          className="addnote"
          onClick={handleReadToInvestClick}
        >
          <Link className="Invest_button">
            <h4>Ready To Invest</h4>
          </Link>
        </div>
      )}

      {roleType === 2 && investmentCreated && !investmentShared && (
        <div className="addnote disabled">
          <div style={{display: "flex", alignItems: "center"}}>
            <span style={{fontWeight: "bold"}}>Investment Details Shared</span>
            <span
              style={{
                width: "10px",
                height: "10px",
                backgroundColor: "green",
                borderRadius: "50%",
                display: "inline-block",
                marginLeft: "8px",
              }}
            ></span>
          </div>
        </div>
      )}

      {roleType === 2 && (
        <div
          className="addnote"
          onClick={handleAddInvestmentClick}
        >
          <Link className="Invest_button">
            <h4>Add Investment</h4>
          </Link>
        </div>
      )}

      {/* contact */}

      <div
        className="addnote"
        onClick={Upload_Pdf}
      >
        <Link
          className="Invest_button"
          to={`/${selectedData?._id}/setup-doc`}
        >
          <h4>Upload Contract</h4>
        </Link>
      </div>

      {roleType === 4 && investmentData?.is_ready && (
        <div className="investment-status-container">
          {GetPaymentIfPaid ? (
            <div className="AssetCard_investment">
              <h4 className="investment-status-header">Investment Status</h4>
              <img
                src={GetPaymentIfPaid.imageUrl}
                alt="Payment"
                className="investment-status-img"
              />
              <p className="investment-status-details">Value: ${GetPaymentIfPaid?.paymentDetails?.value.toLocaleString()}</p>
            </div>
          ) : (
            roleType === 4 &&
            investmentData?.is_ready && (
              <div
                className="addnote"
                onClick={handleInvestmentDialogClick}
              >
                <Link className="Invest_button">
                  <h4>Invest Now</h4>
                </Link>
              </div>
            )
          )}
        </div>
      )}

      {roleType === 4 && (
        <div className="addnote">
          <Link
            className="Invest_button"
            to={`/${selectedData?._id}/setup-doc`}
          >
            <h4>View Contarct</h4>
          </Link>
        </div>
      )}

      <div className="addnote">
        <Link className="ChatNow_button">
          <h4>Chat Now</h4>
        </Link>
      </div>

      <ReadToInvest
        open={openReadToInvest}
        onClose={() => handleDialogClose("readToInvest")}
        iban={iban}
        bic={bic}
        setIban={setIban}
        setBic={setBic}
        handleInvestment={handleInvestment}
      />
      <InvestmentDialog
        open={openInvestmentDialog}
        onClose={() => handleDialogClose("investmentDialog")}
        investmentData={investmentData}
        id={selectedData?._id}
        dispatch={dispatch}
      />
      <PaymentValueDialog
        open={openPaymentValueDialog}
        onClose={() => handleDialogClose("paymentValue")}
        onSubmit={handlePaymentValueSubmit}
        investmentData={investmentData}
      />
      <Send_Contarct
        open={upload_pdf}
        onClose={() => handleDialogClose("Send_Contract")}
        selectedData={selectedData}
        getpdfurldata={getpdfurldata}
        pdfUrl={pdfUrl}
      />
      <PDF_Viewer
        open={view_pdf}
        onClose={() => handleDialogClose("View_pdf")}
        handleFileChange={handleFileChange}
        preview={pdfPreview}
        selectedData={selectedData}
        pdfUrl={pdfUrl}
      />
    </div>
  );
};

export default AssetCardForPortfolio;

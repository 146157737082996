const Endpoint = {
  // Check Tokken Expire

  checkTokken: "api/auth/checkToken",

  // User
  userlogin: "/api/auth/login",
  getUserDetails: "api/auth/user-details",
  Signupdata: "api/auth/register",
  updatepassword: "api/auth/update-password",
  getUserPortfolio: "users/asset-discovery",
  UserLogIn: "api/auth/login",
  PostQuestionsData: "api/auth/persona/add",
  VerifyEmail: "/api/auth/sendVerificationEmail",
  Edit_User: "api/auth/edit/user",
  Upload_profile_image: "/api/auth/uploads/profile",

  // Assets
  // getAsset:`api/assets/search/assets/?assets_name=${query}`,
  PostLoanSetupData: "api/loan/add",
  FetchValuationInfo: "/api/deal-economics/valuation",
  fetchCrownFunding: "/api/crowdfunding",
  Asset_Views_Count: "/api/assets/views",
  Get_Dispositions: "api/assets/AssetDiscovery/getDispositionsWithAssets",
  Count_increment: "/api/assets/persona/increment_preference",
  Get_crowdfunding: "api/crowdfunding",
  Search_Assects: "/api/assets/search_assets",

  // SETUP_SYNDICATE
  PostSetupSyndicate: "api/add-Syndicate",
  GetSyndicates: "api/Syndicates",

  // Manage Syndicate
  PostManageSindicate: "/api/Add/manageStakeholders",

  // portfolio
  AssetHeader: "api/Portfolio/getAccessGivenByEmail",
  Maintenance: "api/Portfolio/getallownerdata",
  getAdmindata: `api/Portfolio/get-portfolio-by-ID`,
  addingfetchinvistor: "api/Portfolio/giveAsccess",
  Sendemailtgiveaccess: "api/Portfolio/giveAccess",
  linechartdata: "/api/Portfolio/getallownerdata",
  getassetbyid: "api/Portfolio/getallownerdata",
  getallownerdata: "api/Portfolio/getallownerdata",
  getassectbyemail: "api/Portfolio/getAccessGivenByAdmin",
  fetchMurndataByEmail: (email) => `api/murndata/get-murndata-by-email?email=${email}`,
  UpdateAsset: (id) => `/api/portfolio/updateAsset/${id}`,
  // Deals
  startDeals: (id) => `api/Deals/StartDeals/${id}`,
  addCheckList: (id) => `/api/assets/:assetId`,
  Similar_assets: (assetId) => `api/assets/GetSimilarProperties/${assetId}`,

  // portfolio -
  getportfolio: "/api/Portfolio/get-portfolio",
  getallassets: "api/assets/search/assets/",
  get_OwnerOperator: "/api/Portfolio/getallownerdata",
  upload_imageFor_Assets: "/api/Portfolio/image_upload",
  getDispositionsWithAssets: "api/assets/AssetDiscovery/getDispositionsWithAssets",
  uploaded_pdfs: "api/Portfolio/uploaded_pdfs",
  get_pdf: "/api/Deals/get_pdfdata",
  uploadpdfdata: "api/Portfolio/uploadPdf",
  delete_image: "/api/Portfolio/delete_image",

  // Edit PDF -
  Upload_PDF: "api/Portfolio/uploaded_pdfs",
  get_editedPDF: "api/Deals/getEditedPdf",
  Add_EditedPDF: "api/Portfolio/uploadPdf",
  getPDF_data: "/api/Deals/get_pdfdata",
  Edited_Pdf_data: "/api/Deals/edited_PDF",
  Upload_Contarct: "/api/Deals/Contract_pdfUpload",
  get_Contract: "/api/Deals/get_pdfdata",

  // Payment -
  InvestNow: "/api/investstnow",
  Add_InvestNow: "api/investnow",
  GetInvestmentStatus: "/api/GetInvestmentStatus",
  Upload_PaymentProof: "/api/submitPaymentValue",
  get_paymentsDetails: "/api/GetPaymentData",
  GetPaymentDetailsWithAssetInfo: "api/GetPaymentDetailsWithAssetInfo",
  Get_Edited_PDF_assectcard: "/api/Deals/getEditedPdf",

  // Admin Access Get
  GivenAdminGivenAccess: "api/Portfolio/updateAccess",
  GiveAccess: "/api/Portfolio/giveAccess",
  SendAccess: "/api/addinvestor",
  getDispositionsWithAssets: "api/assets/AssetDiscovery/getDispositionsWithAssets",
  crowdfunding: "api/crowdfunding",
  syndicates: "api/syndicates",

  //  Adding CroudFunding -

  AddCroudFunding: "/api/add-crowdfunding",

  // Get Budgeting Data -

  getbudgetdata: "/api/Portfolio/getBudgetData",
  genarteBudgetdata: "/api/Portfolio/generate_property",
  ExportasExcel: "/api/Portfolio/ExportAsExcelData",

  // DashBoard -
  getmaintaincedata: "/api/Portfolio/getallownerdata",
  get_maintaince_table: "/api/Portfolio/Maintenance_Table",

  fetchFinancial: "api/Portfolio/Budgeting",
  fetchBudgetData: "/api/Portfolio/getBudgetData",
  generate_property: "/api/Portfolio/generate_property",
  fetchAnnualFinancialData: "api/Portfolio/OperatingIncomeExpences",
  fetchUnitTurnoverData: "api/Portfolio/UnitTurnover",
  Most_Profitable_Renters: "/api/Portfolio/Most_Profitable_Renters",
  KPI: "/api/Portfolio/KPI",
  Unit_Breakdown: "/api/Portfolio/Unit_Breakdown",

  // Syndicate -
  StartSyndciate: "/api/uploadimage",

  // Profile Disposition -
  disposition: "/api/assets/AssetDiscovery",

  // Payments
  PaymentsDetails: "/api/PaymentsDetails",
  paymentconfirm: "/api/paymentConfirmation",
  storepaymentdetails: "/api/StorePaymentValue",

  // Deals -
  GetDetails: "api/Deals/getDeals",
  GetAllDealsSyndicate: "api/asset/GetAllDealsSyndicate",
  sendemail: "api/getaddinvestor",

  // Notification
  getNotification: "api/Deals/notifications",
  readNotnotification: "api/Deals/mark_as_read",

  // To Do ----
  Addtodo: "/api/assets",

  // Investment -
  getallInvestment: "/api/GetInvestmentStatus",
  readytoinvest: "/api/UserDetailsWithReadyToInvestimage",
  Contract_pdfUpload: "/api/Deals/Contract_pdfUpload",

  // Task Section -
  getalltask: "api/Task/gettask",
  addtask: "/api/Task/addtask",

  // Deals
  startDealsredux: "api/Deals/StartDeals",
  updateAssetredux: "api/portfolio/updateAsset",

  // Asset Discovery -
  Maintenance_Table: "/api/Portfolio/Maintenance_Table",
  getallownerdata: "/api/Portfolio/getallownerdata",
  upload_image: "/api/portfolio/upload-image",
  OperatingIncomeExpences: "api/Portfolio/OperatingIncomeExpences",
  get_Asset_Dashboard: "api/Portfolio/get_Asset_Dashboard",
  ExportASExcelData: "/api/Portfolio/ExportASExcelData",
  generate_property: "/api/Portfolio/generate_property",
  getBudgetData: "/api/Portfolio/getBudgetData",
  uploadExcel: "/api/Portfolio/uploadExcel",

  // Open API chat -

  SendDatatoOpenAI: "api/openai/SendDatatoOpenAI",
  openapichat: "api/chat",

  // manageStakeholders

  getmanageStakeholders: "api/GetAll/manageStakeholders",
  deletemanageStakeholders: "/api/manageStakeholders",
  addmanageStakeholders: "/api/Add/manageStakeholders/Add",
  upload_Profile_images: "/api/upload_Profile_images",

  // Utility

  addUtility: "api/assets",

  // Upload PDF

  uploadPdf: "/api/Portfolio/uploadPdf",
  upload_image_card: "/api/portfolio/upload-image",
  updateAsset: "api/portfolio/updateAsset",

  // Assets EndPoints
  asssets: "/api/assets",
  employee_Chat_Access: "/api/Portfolio/employee-Chat-Access",

  // investment
  investment: "/api/investment",
  getAllEmployeeList: "api/Task/users/getallEmployeeList",
  createTaskWithEmployee: "api/Task/createTaskWithEmployee",
  getoldchats: "/api/openai/GetAllChatsForUser",
  GetAllChatsForUser: "/api/openai/GetAllChatsForUser",
  getmessages: "api/openai/GetChatHistory",
  StartChat: "https://aiapi-staging.lilypads.com/api/startchat",
  continuechat: "https://aiapi-staging.lilypads.com/api/continuechat1",
  refreshchat:"https://aiapi-staging.lilypads.com/api/refreshchat",
  Userlist: "api/Task/users/getallEmployeeList?email=murn@test.com",
  Implement: "api/openai/Implemented/",
  FollowUp: "/api/openai/FollowUp",
  getAllstatus: "api/openai/getStatusesByMessage",

  // send Feedback -
  sendfeedback: "api/openai/Submit_feedBack",
  getfeedback: "api/openai/get_feedBack",
  addassigntask: "api/openai/Assigned",

  //feedback -
  InviteaEmployeetoAsset: "/api/Portfolio/giveAccess",
  Likechat:"api/openai/likeFeedback"
};

export default Endpoint;

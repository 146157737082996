import React from "react";
import ReactApexChart from "react-apexcharts";

const renderPieChart = (pieData) => {
  if (!pieData) return null;

  return (
    <div className="chart">
      <ReactApexChart
        type="pie"
        series={pieData.map((item) => item.percentage)}
        options={{
          labels: pieData.map((item) => item.category),
          title: {text: "Occupancy Status"},
        }}
        height={300}
      />
    </div>
  );
};

const renderLineChart = (lineData) => {
  if (!lineData) return null;

  return (
    <div className="chart">
      <ReactApexChart
        type="line"
        series={[
          {name: "Average Rent", data: lineData.avg_rent},
          {name: "Occupancy Rate", data: lineData.occupancy_rate},
        ]}
        options={{
          xaxis: {
            categories: lineData.dates,
            // title: {text: "Dates"},
            axisBorder: {show: true},
            axisTicks: {show: true},
          },
          yaxis: {
            // title: {text: "Values"},
            axisBorder: {show: true},
            axisTicks: {show: true},
          },
          grid: {
            show: false,
          },
          chart: {
            toolbar: {show: false},
            background: "transparent",
          },
          title: {text: "Trends Over Time"},
          legend: {
            show: false, // Hides the legend
          },
          tooltip: {
            enabled: false, // Disables hover tooltips
          },
        }}
        height={300}
      />
    </div>
  );
};

export {renderPieChart, renderLineChart};

import { Empty, Image, List, Spin, Typography } from "antd";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  Timestamp,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import attachment from "../../assets/dealsroom/svg/attachment.svg";
import emoji from "../../assets/dealsroom/svg/emoji.svg";
import imgupload from "../../assets/dealsroom/svg/imgupload.svg";
import { firestore } from "../../firebase";
import {
  setNotificationCount,
  setToggleRefresh,
} from "../../store/reducers/userDetailsSlice";
import { formatTimestamp } from "../../utils/helpers";
import default_profile from "../../assets/Image/user pic.png";
import axios from "axios";
import "./style.scss";
import { getChatAccessUsers } from "../../utils/Axios";
import sendIcon from "../../assets/Image/send_icon.svg";
import Endpoint from "../../utils/Endpoint";

const { Text } = Typography;

const ChatContainer = ({
  chats,
  setChats,
  setSelectedChatId,
  selectedChatId,
  handleChatClick,
  expanded,
  assetImgId,
  setParticipantsMembers,
}) => {
  const {
    userInformation,
    chatId,
    notificationCount,
    assetName,
    allowToStartGroup,
    assetId,
  } = useSelector((state) => state.userInfo);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const currentUserEmail = userInformation?.email;
  const userRole = userInformation?.roleTypes;
  const userProfile = userInformation?.profile_image || default_profile;
  const { assets } = useSelector((state) => state.AdminGivenAccess);
  const [groupParticipants, setGroupParticipants] = useState([]);
  const { idToken } = useSelector((state) => state.auth);
  const [isEmployeeFetch, setIsEmployeeFetch] = useState(false);
  const [isAbleToSendMsg, setIsAbleToSendMsg] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);
  const dispatch = useDispatch();

  const fetchChatAccessUsers = async (idToken, assetId) => {
    try {
      const res = await getChatAccessUsers({ idToken, assetId });

      if (res) {
        setIsEmployeeFetch(true);
      }
      const members = res?.map((item) => item?.email);
      setGroupParticipants(members);
      setParticipantsMembers([currentUserEmail, chatId, ...members]);
      setParticipantsMembers([
        {
          email: currentUserEmail,
          profile_image: "",
        },
        {
          email: currentUserEmail,
          profile_image: "",
        },
        ...res,
      ]);
    } catch (error) {
      setIsEmployeeFetch(true);
      setParticipantsMembers([currentUserEmail, chatId]);
    }
  };

  useEffect(() => {
    fetchChatAccessUsers(idToken, assetId);
  }, [assetId]);

  useEffect(() => {
    if (currentUserEmail) {
      const fetchChats = async () => {
        setLoading(true);
        try {
          const chatsRef = collection(firestore, "chats");
          const q = query(
            chatsRef,
            where("participants", "array-contains", currentUserEmail)
          );
          const snapshot = await getDocs(q);
          const fetchedChats = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setChats(fetchedChats);
        } catch (error) {
      
        } finally {
          setLoading(false);
        }
      };

      fetchChats();
    }
  }, [currentUserEmail]);

  useEffect(() => {
    dispatch(
      setNotificationCount(
        messages?.filter((item, index) => {
          if (userRole === 2) {
            return item?.userType === "investor";
          } else {
            return item?.userType === "owner";
          }
        })
      )
    );
  }, [messages]);

  useEffect(() => {
    if (currentUserEmail && chatId && assetId && assetName && isEmployeeFetch) {
      const fetchOrCreateChat = async () => {
        setLoading(true);
        try {
          const chatsRef = collection(firestore, "chats");
          const q = query(
            chatsRef,
            where("participants", "array-contains", currentUserEmail),
            where("assetId", "==", assetId) 
          );
          const snapshot = await getDocs(q);
          const existingChat = snapshot.docs.find((doc) => {
            const data = doc.data();
            return (
              data.participants.includes(chatId) &&
              data.participants.includes(currentUserEmail) &&
              data.assetId === assetId
            );
          });

          if (existingChat) {
            const chatDocRef = doc(chatsRef, existingChat.id);
            if (existingChat.data().assetName !== assetName) {
              await updateDoc(chatDocRef, { assetName });
            }
          } else {
            const newChatRef = doc(chatsRef);
            const newChatId = newChatRef.id;
            let members = [currentUserEmail, chatId];
            for (let i = 0; i < groupParticipants?.length; i++) {
              members?.push(groupParticipants[i]);
            }

            const chatData = {
              type: "direct",
              participants: members,
              lastMessage: "",
              user_profile: userProfile,
              assetName: assetName,
              assetId: assetId,
            };

            await setDoc(newChatRef, chatData);

            setSelectedChatId(newChatId);
          }
        } catch (error) {
        
        } finally {
          setLoading(false);
          dispatch(setToggleRefresh());
        }
      };

      fetchOrCreateChat();
    }
  }, [currentUserEmail, chatId, userProfile, assetId, isEmployeeFetch]);
  useEffect(() => {
    if (selectedChatId) {
      const messagesRef = collection(
        firestore,
        "chats",
        selectedChatId,
        "messages"
      );
      const q = query(messagesRef, orderBy("timestamp"));

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const fetchedMessages = snapshot.docs.map((doc) => doc.data());
        setMessages(fetchedMessages);
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      });

      return () => unsubscribe();
    }
  }, [selectedChatId]);

  const handleSendMessage = async () => {
    if (selectedChatId) {
      try {
        await addDoc(
          collection(firestore, "chats", selectedChatId, "messages"),
          {
            senderEmail: currentUserEmail,
            text: newMessage,
            timestamp: Timestamp.now(),
            userType:
              userRole === 2
                ? "owner"
                : userRole === 4
                ? "investor"
                : userRole === 3
                ? "employe"
                : "",
            avatar: userProfile,
            user_name: `${userInformation?.name} ${userInformation?.surname}`,
            seen: false,
            images: imageUrl,
            assetId: assetId,
          }
        );
        await setDoc(
          doc(firestore, "chats", selectedChatId),
          {
            lastMessage: newMessage,
          },
          { merge: true }
        );
        setNewMessage("");
        setLocalImgUrl([]);
      } catch (error) {
      
      }
    } else {
    }
  };

  useEffect(() => {
    if (selectedChatId) {
      const messagesRef = collection(
        firestore,
        "chats",
        selectedChatId,
        "messages"
      );
      const q = query(messagesRef, orderBy("timestamp"));

      const unsubscribe = onSnapshot(q, async (snapshot) => {
        const fetchedMessages = snapshot.docs.map((doc) => doc.data());
        const batch = writeBatch(firestore);
        snapshot.docs.forEach((doc) => {
          const messageData = doc.data();
          if (
            !messageData.seen &&
            messageData.senderEmail !== currentUserEmail
          ) {
            batch.update(doc.ref, { seen: true });
          }
        });

        await batch.commit();

        setMessages(fetchedMessages);
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      });

      return () => unsubscribe();
    }
  }, [selectedChatId]);

  const [selectedFile, setSelectedFile] = useState(null);
  const [imageUrl, setImageUrl] = useState([]);
  const [localImgUrl, setLocalImgUrl] = useState(null);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const validFiles = files.filter(
      (file) => file.type === "image/jpeg" || file.type === "image/png"
    );

    if (validFiles) {
      const fileURLs = validFiles.map((file) => URL.createObjectURL(file));
      setSelectedFile(validFiles);
      setLocalImgUrl(fileURLs);
    } else {
      alert("Please select only JPG or PNG files.");
    }
  };

  const handleRemoveImage = (index) => {
    setLocalImgUrl(localImgUrl?.filter((item, i) => i !== index));
    setSelectedFile(selectedFile?.filter((item, i) => i !== index));
  };

  useEffect(() => {
    if (imageUrl?.length > 0) {
      handleSendMessage();
    }
  }, [imageUrl]);

  const handleUpload = async (e) => {
    setImgLoading(true);
    e.preventDefault();
    if (selectedFile?.length > 0) {
      const formData = new FormData();
      selectedFile.forEach((file, index) => {
        formData.append(`chat_image`, file);
      });

      try {
        const response = await axios.post(
          `${Endpoint.upload_imageFor_Assets}?assetId=${assetImgId}`,
          formData,
          {
            headers: {
              "auth-token": idToken,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response?.data?.success) {
          setImageUrl(response?.data?.data);
          setImgLoading(false);

          setSelectedFile([]);
        } else {
          setSelectedFile([]);
          setImageUrl([]);
          setIsAbleToSendMsg(true);
          setImgLoading(false);
        }
      } catch (error) {
        setIsAbleToSendMsg(true);
        setImgLoading(false);
      } finally {
        setSelectedFile([]);
        setIsAbleToSendMsg(true);
        setImgLoading(false);
      }
    } else {
   
      setIsAbleToSendMsg(true);
      setImgLoading(false);
    }
  };

  useEffect(() => {
    setSelectedFile([]);
    setLocalImgUrl([]);
  }, [selectedChatId]);

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      {loading ? (
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          {selectedChatId ? (
            <>
              <div style={{ flex: 1, padding: "10px", overflowY: "auto" }}>
                <List
                  dataSource={messages}
                  renderItem={(msg) => (
                    <List.Item
                      key={msg.timestamp.toMillis()}
                      style={{
                        display: "flex",
                        justifyContent:
                          msg.senderEmail === currentUserEmail
                            ? "flex-end"
                            : "flex-start",
                      }}
                    >
                      {msg.senderEmail === currentUserEmail ? (
                        <>
                          <div className={`seller-chat-activity-right `}>
                            <div className="chat-user">
                              <img id="chatimg" src={msg?.avatar} alt="" />
                              <span>{msg?.user_name}</span>
                              <span>
                                {formatTimestamp(
                                  msg?.timestamp?.seconds,
                                  msg?.timestamp?.nanoseconds
                                )}
                              </span>
                            </div>
                            {msg?.text !== "" ? (
                              <div
                                className={`chat-box-right  ${
                                  expanded && "seller-chat-right-expanded"
                                }`}
                                style={{width:'72%'}}
                              >
                                <p> {msg?.text}</p>
                              </div>
                            ) : (
                              <div
                                className="chat_image_container"
                                style={{ direction: "rtl" }}
                              >
                                {msg?.images?.length > 0 &&
                                  msg?.images?.map((src, index) => (
                                    <div className="img_wrapper">
                                      <img src={src} key={index} loading="lazy"/>
                                      {/* <Image width={100} src={src} /> */}
                                    </div>
                                  ))}
                              </div>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          {" "}
                          <div className="seller-chat-activity-left">
                            <div className="chat-user">
                              <img id="chatimg" src={msg?.avatar} alt="" />
                              <span>{msg?.user_name}</span>
                             <span> {formatTimestamp(
                                msg?.timestamp?.seconds,
                                msg?.timestamp?.nanoseconds
                              )}</span>
                            </div>
                            {msg?.text !== "" ? (
                              <div
                                className={`chat-box-right  ${
                                  expanded && "seller-chat-right-expanded"
                                }`}
                                style={{width:'72%'}}
                              >
                                <p> {msg?.text}</p>
                              </div>
                            ) : (
                              <div className="chat_image_container">
                                {msg?.images?.length > 0 &&
                                  msg?.images?.map((src, index) => (
                                    <div className="img_wrapper">
                                      <img src={src} key={index} loading="lazy"/>
                                      {/* <Image width={100} src={src} /> */}
                                    </div>
                                  ))}
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </List.Item>
                  )}
                />
                <div ref={messagesEndRef} />
              </div>
              <div className="image_container">
                {localImgUrl?.length > 0 &&
                  localImgUrl?.map((img, index) => (
                    <div className="img_box">
                      <img src={img} alt="" width="100%" />
                      {!imgLoading && (
                        <button
                          className="closebtn"
                          onClick={() => handleRemoveImage(index)}
                        >
                          X
                        </button>
                      )}
                      {imgLoading && (
                        <div className="container_loading">Sending..</div>
                      )}
                    </div>
                  ))}
              </div>
              <div className="chat-input-container">
                <div className="seller-chat-box">
                  <input
                    type="input"
                    id="input-box"
                    placeholder="Type your message"
                    className="text-input-container"
                    name="text-box"
                    autoFocus={false}
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    autoComplete="off"
                  />
                  <div className="chat-attacment">
                    <img
                      className={`send_icon ${
                        newMessage?.length > 0 || selectedFile?.length > 0
                          ? ""
                          : "hidden"
                      }`}
                      src={sendIcon}
                      width="25px"
                      // style={{
                      //   background: "none",
                      //   border: "none",
                      //   outline: "none",
                      // }}
                      onClick={
                        selectedFile?.length > 0
                          ? handleUpload
                          : handleSendMessage
                      }
                    />

                    <label htmlFor="img_upload">
                      <img
                        src={imgupload}
                        alt=""
                        width="100%"
                        className="chat_action_icon"
                      />
                    </label>
                    <input
                      type="file"
                      multiple
                      accept="image/jpeg, image/png"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                      id="img_upload"
                    />
                    <img src={attachment} alt="" className="chat_action_icon" />
                    <img src={emoji} alt="" className="chat_action_icon" />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div style={{ padding: "20px", textAlign: "center" }}>
              <Empty description="No Chats" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ChatContainer;

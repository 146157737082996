import React, { useState } from "react";
import "./Deals.scss";
import apply from "../../assets/svg/apply.svg";
import clear from "../../assets/svg/clear.svg";
import drop from "../../assets/svg/drop.svg";
import cross from "../../assets/svg/cross.svg";
import Checkbox from '../../pages/profile/settings/Checkbox'

const Filter = () => {
    return (
      <div className="filter-wrapper-main">
        <div className="filter-header">
          <span>Filter</span>
          <div>
            <img
              src={apply}
              alt=""
            />
            <span>Apply</span>
          </div>
          <div>
            <img
              src={clear}
              alt=""
            />
            <span>Clear</span>
          </div>
        </div>
        <div className="filter-main">
          <div className="filter-card">
            <div className="filter-c-header">
              <span>Origin</span>
              <img
                src={drop}
                alt=""
              />
            </div>
  
            <div className="filter-c-list">
              <Checkbox label="Lilypads Deals" />
              <span>23</span>
            </div>
            <div className="filter-c-list">
              <Checkbox label="Imported Deals" />
              <span>11</span>
            </div>
          </div>
          <div className="divider"></div>
          <div className="filter-card">
            <div className="filter-c-header">
              <span>Loan</span>
              <img
                src={drop}
                alt=""
              />
            </div>
  
            <div className="filter-c-list">
              <Checkbox label="No Loan " />
              <span>23</span>
            </div>
            <div className="filter-c-list">
              <Checkbox label="Senior Debt" />
              <span>11</span>
            </div>
            <div className="filter-c-list">
              <Checkbox label="Mezzanine Debt " />
              <span>11</span>
            </div>
            <div className="filter-c-list">
              <Checkbox label="Prefered Equity " />
              <span>11</span>
            </div>
            <div className="filter-c-list">
              <Checkbox label="Common Equity" />
              <span>11</span>
            </div>
          </div>
          <div className="divider"></div>
          <div className="filter-card">
            <div className="filter-c-header">
              <span>Author</span>
              <img
                src={drop}
                alt=""
              />
            </div>
            <div className="dropdown-box"></div>
  
            <div className="auther-list">
              <span>Alex Thompson</span>
              <img
                src={cross}
                alt=""
              />
            </div>
            <div className="auther-list">
              <span>Ricky Wong</span>
              <img
                src={cross}
                alt=""
              />
            </div>
          </div>
          <div className="divider"></div>
          <div className="filter-card">
            <div className="filter-c-header">
              <span>Loan</span>
              <img
                src={drop}
                alt=""
              />
            </div>
  
            <div className="filter-c-list">
              <Checkbox label="Active" />
              <span>23</span>
            </div>
            <div className="filter-c-list">
              <Checkbox label="Pending" />
              <span>11</span>
            </div>
            <div className="filter-c-list">
              <Checkbox label="Completed" />
              <span>11</span>
            </div>
          </div>
          <div className="divider"></div>
          <div className="filter-card">
            <div className="filter-c-header">
              <span>Tags</span>
              <img
                src={drop}
                alt=""
              />
            </div>
            <div className="dropdown-box"></div>
  
            <div className="auther-list-w">
              <div className="auther-list">
                <span>Alex Thompson</span>
                <img
                  src={cross}
                  alt=""
                />
              </div>
              <div className="auther-list">
                <span>tag</span>
                <img
                  src={cross}
                  alt=""
                />
              </div>
              <div className="auther-list">
                <span>Empty tag</span>
                <img
                  src={cross}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

export default Filter

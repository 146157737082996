import React, { useState } from 'react';
import './AssetsList.scss';

const propertyData = [
  {
    id: 1,
    image: 'https://via.placeholder.com/60',
    title: 'Lorem ipsum',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ipsum consectetur amet.',
  },
  {
    id: 2,
    image: 'https://via.placeholder.com/60',
    title: 'Lorem ipsum',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ipsum consectetur amet.',
  },
  {
    id: 3,
    image: 'https://via.placeholder.com/60',
    title: 'Lorem ipsum',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ipsum consectetur amet.',
  },
];

const AssetsList = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="assets_list-container">
      <div className="assets_list-content">
        <h2 className="assets_list-title">What can I help with?</h2>
        <div className="assets_list-dropdown-container">
          <button onClick={toggleDropdown} className="assets_list-dropdown-button">
            Select your property type...
            <span className="assets_list-dropdown-arrow">&#9662;</span>
          </button>
          <div className={`assets_list-dropdown-content ${isDropdownOpen ? 'open' : ''}`}>
            {propertyData?.map((property) => (
              <div key={property.id} className="assets_list-dropdown-item">
                <img src={property.image} alt={property.title} className="assets_list-item-image" />
                <div className="assets_list-item-text">
                  <h3 className="assets_list-item-title">{property.title}</h3>
                  <p className="assets_list-item-description">{property.description}</p>
                </div>
              </div>
            ))}
          </div>
          <button className="assets_list-query-button">
            Ask Your Queries
            <span className="assets_list-query-arrow">➜</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AssetsList;

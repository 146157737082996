import React, { useEffect, useState } from "react";
import "./chatdropdown.scss";
import cross from "../../assets/svg/cross.svg";
import expand from "../../assets/svg/expand.svg";
import person from "../../assets/svg/profileAvatar.png";
import building from "../../assets/Image/assetDiscovery/general-bg.png";
import dot from "../../assets/svg/dot-green.svg";
import imgupload from "../../assets/dealsroom/svg/imgupload.svg";
import attachment from "../../assets/dealsroom/svg/attachment.svg";
import emoji from "../../assets/dealsroom/svg/emoji.svg";
import women from "../../assets/dealsroom/images/women.png";
import action from "../../assets/dealsroom/svg/action.svg";
import chatimg from "../../assets/dealsroom/images/chatimg.png";
import dropdown from "../../assets/Image/assetDiscovery/arrow_down.svg";
import video from "../../assets/Image/assetDiscovery/video.svg";
import add from "../../assets/svg/add-gray.svg";
import phone from "../../assets/svg/phone.svg";
import ChatContainer from "../chatBox";
import { useSelector } from "react-redux";
import Select, { components } from "react-select";
import AdminChatContainer from "../chatBox/admin";
import {
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { firestore } from "../../firebase";
import { formatTimestamp } from "../../utils/helpers";
import { useDispatch } from "react-redux";
import {
  setAssetId,
  setAssetName,
  setChatId,
} from "../../store/reducers/userDetailsSlice";

function ChatDropdown({ handleChat }) {
  const [navActive, setNavActive] = useState(1);
  const [expanded, setExpanded] = useState(false);
  const { userInformation, refresh, assetName } = useSelector(
    (state) => state.userInfo
  );
  const [chatsInvestors, setChatsInvestors] = useState([]);
  const dispatch = useDispatch();
  const [chats, setChats] = useState([]);
  const currentUserEmail = userInformation?.email;
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [filteredChats, setFilteredChats] = useState(chats);
  const { selectedProperty } = useSelector((state) => state.portfolio);
  const [currentUser, setCurrentUser] = useState(null);
  const [assetImgId, setAssetImgId] = useState(null);
  const [participantsMembers, setParticipantsMembers] = useState([]);

  useEffect(() => {
    let unsubscribe;

    const fetchMessages = async () => {
      try {
        const messagesCollectionGroup = collectionGroup(firestore, "messages");

        unsubscribe = onSnapshot(messagesCollectionGroup, (snapshot) => {
          const filteredMessages = [];
          snapshot?.forEach((doc) => {
            const messageData = doc.data();
            if (messageData.sender === "user") {
              filteredMessages?.push(messageData);
            }
          });
          setChatsInvestors(filteredMessages);
        });
      } catch (error) {

      }
    };

    fetchMessages();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const userDoc = await getDoc(doc(firestore, "users", currentUserEmail));
        setCurrentUser(userDoc.data());
      } catch (error) {
      
      }
    };

    if (currentUserEmail) {
      fetchCurrentUser();
    }
  }, [currentUserEmail]);

  const handleChatClick = (chatId) => {
    setSelectedChatId(chatId);
  };

  useEffect(() => {
    if (currentUserEmail) {
      const fetchChats = async () => {
        try {
          const chatsRef = collection(firestore, "chats");
          const q = query(
            chatsRef,
            where("participants", "array-contains", currentUserEmail)
          );
          const snapshot = await getDocs(q);

          const fetchedChats = await Promise.all(
            snapshot.docs.map(async (doc) => {
              const messagesRef = collection(
                firestore,
                "chats",
                doc.id,
                "messages"
              );
              const messagesSnapshot = await getDocs(messagesRef);
              const messages = messagesSnapshot.docs.map((msgDoc) =>
                msgDoc.data()
              );

              return {
                id: doc.id,
                ...doc.data(),
                messages,
              };
            })
          );

          setChats(fetchedChats);
        } catch (error) {
        
        }
      };

      fetchChats();
    }
  }, [currentUserEmail, expanded]);

  const CustomOption = (props) => {
    return (
      <components.Option {...props}>{props.data.fullLabel}</components.Option>
    );
  };

  const CustomMultiValueLabel = (props) => {
    return (
      <components.MultiValueLabel {...props}>
        {props.data.label}
      </components.MultiValueLabel>
    );
  };

  const options = chats
    ?.filter((item) => {
      const hasLastMessage = item?.lastMessage !== "";
      const hasImageUrl = item?.messages?.some(
        (message) => message.images && message.images.length > 0
      );
      return hasLastMessage || hasImageUrl;
    })
    ?.map((item) => ({
      value: item?.assetName,
      label:
        item?.assetName?.length > 4
          ? `${item?.assetName?.substring(0, 6)}...`
          : item?.assetName,
      fullLabel: item?.assetName,
    }));

  useEffect(() => {
    if (selectedProperty) {
      setSelectedOptions([
        {
          value: selectedProperty.assetName,
          label:
            selectedProperty?.assetName?.length > 4
              ? `${selectedProperty?.assetName?.substring(0, 6)}...`
              : selectedProperty?.assetName,
          fullLabel: selectedProperty.assetName,
        },
      ]);
    }
  }, [selectedProperty, expanded]);

  const handleSelect = (value) => {
    setSelectedOptions(value);
  };

  useEffect(() => {
    if (
      selectedOptions.length === 0 ||
      userInformation?.roleTypes === 4 ||
      userInformation?.roleTypes === 3
    ) {
      setFilteredChats(chats);
    } else {
      const selectedValues = selectedOptions.map((option) => option.value);
      const filtered = chats.filter((chat) =>
        selectedValues.includes(chat.assetName)
      );
      setFilteredChats(filtered);
    }
  }, [selectedOptions, chats, selectedProperty, expanded]);

  return (
    <div className={`chat_dropdown_wrapper ${expanded && "expand"}`}>
      {!expanded && (
        <div className="chat_dropdown">
          <div className="header">
            <div className="chat_nav">
              <div className=" deals-room-nav asset-nav ass-nav">
                <span
                  className={
                    navActive === 1 && "deals-room-nav-active ut-nav-active"
                  }
                  onClick={() => {
                    setNavActive(1);
                  }}
                >
                  Conversations{" "}
                </span>
                <span
                  className={
                    navActive === 2 && "deals-room-nav-active ut-nav-active"
                  }
                  onClick={() => {
                    setNavActive(2);
                  }}
                >
                  Contacts
                </span>
              </div>{" "}
            </div>

            <div className="header_right">
              <img
                onClick={() => {
                  setNavActive(1);
                  setExpanded(!expanded);
                }}
                src={expand}
                alt=""
              />

              <img
                onClick={handleChat}
                style={{ height: "30px", width: "30px" }}
                src={cross}
                alt=""
              />
            </div>
          </div>
          {userInformation?.roleTypes === 2 && (
            <Select
              isMulti
              name="assets"
              value={selectedOptions}
              options={options}
              className="asset-multi-select"
              classNamePrefix="select"
              placeholder="Enter asset"
              components={{
                Option: CustomOption,
                MultiValueLabel: CustomMultiValueLabel,
              }}
              onChange={handleSelect}
            />
          )}
          <>
            <div className="chat_title">
              <p>Teams</p>{" "}
            </div>

            {filteredChats
              ?.filter((item) => {
                const hasLastMessage = item?.lastMessage !== "";
                const hasImageUrl = item?.messages?.some(
                  (message) => message.images && message.images.length > 0
                );
                return hasLastMessage || hasImageUrl;
              })
              ?.map((user, index) => {
                const otherParticipant = user.participants.find(
                  (email) => email !== currentUserEmail
                );
                const displayName =
                  otherParticipant && otherParticipant.split("@")[0];
                return (
                  <TeamsBox
                    name={"Office Managers"}
                    expanded={true}
                    active={true}
                    navActive={navActive}
                    user={user}
                    displayName={displayName}
                    setNavActive={setNavActive}
                    dispatch={dispatch}
                    setExpanded={setExpanded}
                    setSelectedChatId={setSelectedChatId}
                    selectedChatId={selectedChatId}
                    handleChatClick={handleChatClick}
                    currentUser={currentUser}
                    setAssetImgId={setAssetImgId}
                    userInformation={userInformation}
                  />
                );
              })}

            {/* <TeamsBox name={"Finance Teams"} active={false} />

              <div className="chat_title">
                <p>Personal</p>{" "}
              </div> */}

            {/* <PersonalBox name={"Delia Saunders"} />
              <PersonalBox name={"Delia Saunders"} />
              <PersonalBox name={"Delia Saunders"} /> */}
          </>

          {/* {navActive === 2 && <ContactsBox />} */}
        </div>
      )}

      {expanded && (
        <div style={{ display: "flex" }}>
          <div className="chat_dropdown">
            <div className="header">
              <div className="chat_nav">
                <div className=" deals-room-nav asset-nav ass-nav">
                  <span
                    className={
                      navActive === 1 && "deals-room-nav-active ut-nav-active"
                    }
                    onClick={() => {
                      setNavActive(1);
                    }}
                  >
                    Conversations{" "}
                  </span>
                  <span
                    className={
                      navActive === 2 && "deals-room-nav-active ut-nav-active"
                    }
                    onClick={() => {
                      setNavActive(2);
                    }}
                  >
                    Contacts
                  </span>
                </div>{" "}
              </div>

              <div className="header_right">
                <img
                  onClick={() => {
                    setNavActive(1);
                    setExpanded(!expanded);
                  }}
                  src={expand}
                  alt=""
                />
                {!expanded && (
                  <img
                    onClick={handleChat}
                    style={{ height: "30px", width: "30px" }}
                    src={cross}
                    alt=""
                  />
                )}{" "}
              </div>
            </div>

            {/* {navActive === 1 && ( */}
            {userInformation?.roleTypes === 2 && (
              <Select
                isMulti
                name="assets"
                value={selectedOptions}
                options={options}
                className="asset-multi-select"
                classNamePrefix="select"
                placeholder="Enter asset"
                components={{
                  Option: CustomOption,
                  MultiValueLabel: CustomMultiValueLabel,
                }}
                onChange={handleSelect}
              />
            )}
            <>
              <div className="chat_title">
                <p>Teams</p>{" "}
              </div>
              {filteredChats
                ?.filter((item) => {
                  const hasLastMessage = item?.lastMessage !== "";
                  const hasImageUrl = item?.messages?.some(
                    (message) => message.images && message.images.length > 0
                  );
                  return hasLastMessage || hasImageUrl;
                })
                ?.map((user, index) => {
                  const otherParticipant = user.participants.find(
                    (email) => email !== currentUserEmail
                  );
                  const displayName =
                    otherParticipant && otherParticipant.split("@")[0];
                  return (
                    <TeamsBox
                      name={"Office Managers"}
                      expanded={true}
                      active={true}
                      navActive={navActive}
                      user={user}
                      displayName={displayName}
                      setNavActive={setNavActive}
                      dispatch={dispatch}
                      setExpanded={setExpanded}
                      setSelectedChatId={setSelectedChatId}
                      selectedChatId={selectedChatId}
                      handleChatClick={handleChatClick}
                      currentUser={currentUser}
                      setAssetImgId={setAssetImgId}
                      userInformation={userInformation}
                    />
                  );
                })}

              {/* <TeamsBox
                name={"Finance Teams"}
                expanded={true}
                navActive={navActive}
                setNavActive={setNavActive}
              /> */}

              {/* <div className="chat_title">
                <p>Personal</p>{" "}
              </div> */}

              {/* <PersonalBox name={"Delia Saunders"} />
              <PersonalBox name={"Delia Saunders"} />
              <PersonalBox name={"Delia Saunders"} /> */}
            </>
            {/* )} */}

            {/* {navActive === 2 && <ContactsBox />} */}
          </div>
          <ChatBox
            handleChat={handleChat}
            expanded={expanded}
            userInformation={userInformation}
            chats={chats}
            setChats={setChats}
            selectedChatId={selectedChatId}
            setSelectedChatId={setSelectedChatId}
            handleChatClick={handleChatClick}
            assetImgId={assetImgId}
            setParticipantsMembers={setParticipantsMembers}
            participantsMembers={participantsMembers}
            assetName={assetName}
          />
        </div>
      )}
    </div>
  );
}

export default ChatDropdown;

const TeamsBox = ({
  name,
  active,
  navActive,
  setNavActive,
  expanded,
  user,
  dispatch,
  setExpanded,
  displayName,
  handleChatClick,
  currentUser,
  selectedChatId,
  setAssetImgId,
  userInformation
}) => {
  const isCurrentUser = user.participants.includes(currentUser?.email);

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        handleChatClick(user?.id);
        setExpanded(true);
        setAssetImgId(user?.assetId);
        dispatch(setAssetId(user?.assetId));
        dispatch(setAssetName(user?.assetName));
        
      }}
      className={`teambox ${user?.id === selectedChatId && "active"} ${
        isCurrentUser && "current-user"
      }`}
      style={{ cursor: "pointer" }}
    >
      <div className="teambox_left">
        {userInformation?.roleTypes === 2 && <img src={user?.user_profile} alt="" />}
      </div>
      <div className="teambox_right">
        <div
          className="right_upper"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "2px",
            alignItems: "start",
          }}
        >
          <h3>{`${displayName}`}</h3>
          <span>{user?.assetName}</span>
        </div>
        <div>
        <p className="text">
  {user?.lastMessage?.length > 20 
    ? `${user.lastMessage.slice(0, 20)}...` 
    : user?.lastMessage}
</p>

        </div>
      </div>
    </div>
  );
};

const PersonalBox = ({ name }) => {
  return (
    <div className="teambox personalbox">
      <div className="teambox_left">
        <img src={person} alt="" />
      </div>
      <div className="teambox_right">
        <div className="right_upper">
          <h3>{name}</h3>
          <span className="time">05:52 AM</span>
        </div>
        <div>
          <p className="text">
            We have lake-front vacation rentals. No specific liability waiver
            for that, but…
          </p>
        </div>

        <div className="right_lower">
          <img src={building} alt="" />
          <p>Bauhaus Archive</p>
        </div>
      </div>
    </div>
  );
};

const ContactsBox = () => {
  return (
    <div className="contactsbox">
      <p className="title">Favourites</p>
      <Contact name={"Lift Lights"} />
      <Contact name={"JP Captain Morgan"} />
      <p style={{ marginTop: "20px" }} className="title">
        Contact
      </p>
      <Contact name={"Francisco Gibbs"} active={true} />
      <Contact name={"Francisco Gibbs"} active={true} />
      <Contact name={"Lift Lights"} active={true} />
      <Contact name={"Lift Lights"} active={true} />
      <Contact name={"Lift Lights"} active={true} />
      <Contact name={"Lift Lights"} active={true} />
      <p style={{ marginTop: "20px" }} className="title">
        Groups
      </p>
      <Contact name={"Office Managers"} group={true} />
      <Contact name={"Office Managers"} group={true} />
    </div>
  );
};

const Contact = ({ active, name, group }) => {
  return (
    <div className="contact">
      <div className="contact_left">
        <img src={person} alt="" />
        <div>
          <p className="name">{name}</p>
          {group && (
            <div className="left_lower">
              <span>Lucy, Claire, Sarah</span>
            </div>
          )}
        </div>
      </div>
      {active && (
        <div className="contact_right">
          <img src={dot} alt="" />
        </div>
      )}
    </div>
  );
};

const ChatBox = ({
  expanded,
  handleChat,
  userInformation,
  setChats,
  chats,
  setSelectedChatId,
  selectedChatId,
  assetImgId,
  setParticipantsMembers,
  participantsMembers,
  assetName,
}) => {
  return (
    <div className="chatbox">
      <div className="chatbox_header">
        <div className="chatbox_header_left">
          {!expanded && (
            <img style={{ rotate: "90deg" }} src={dropdown} alt="" />
          )}

          <h3> {assetName}</h3>
        </div>

        <div className="chatbox_header_right">
          {expanded ? (
            <>
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                {" "}
                {participantsMembers?.map((user, index) => {
                  return <img src={person} alt="" />;
                })}
              </div>
              {/* <div>
                <img src={person} alt="" />
                <img src={person} alt="" />
                <img src={person} alt="" />
                <img src={person} alt="" />

                <img src={add} alt="" />
              </div> */}

              {/* <div>
                <img src={phone} alt="" />
                <img src={video} alt="" />
              </div> */}

              <img onClick={handleChat} src={cross} alt="" />
            </>
          ) : (
            <img src={cross} alt="" />
          )}
        </div>
      </div>
      <div>
        <div
          className={`seller-chat-container  ${
            expanded && "seller-chat-expanded"
          }`}
        >
          {/* <div className="seller-chat-activity">
            <div className={`seller-chat-activity-right `}>
              <div className="chat-user">
                <img id="chatimg" src={women} alt="" />
                <span>Norman Hernandez</span>
                <span>05:10 AM</span>
              </div>
              <div
                className={`chat-box-right  ${
                  expanded && "seller-chat-right-expanded"
                }`}
              >
                <p>
                  I always think teenagers must hate my place as there is
                  nothing to do but relax and enjoy the sea view and sunsets and
                  read books which is not a very teenagery thing to do (my idea
                  of
                </p>
              </div>
            </div>
            <div className="seller-chat-activity-left">
              <div className="chat-user">
                <img id="chatimg" src={women} alt="" />
                <span>Norman Hernandez</span>
                <span>05:10 AM</span>
              </div>
              <div
                className={`chat-box-left chat-color-unread  ${
                  expanded && "seller-chat-left-expanded"
                }`}
              >
                <p>
                  {" "}
                  We wanted to see how others handle a waiver of liability to
                  use of Kayaks, boats, swimming. 🛶🏖
                </p>
                <img src={action} alt="" />
              </div>
            </div>
            <div className="seller-chat-activity-right">
              <div className="chat-user">
                <img id="chatimg" src={women} alt="" />
                <span>Norman Hernandez</span>
                <span>05:10 AM</span>
              </div>
              <div
                className={`chat-box-right  ${
                  expanded && "seller-chat-right-expanded"
                }`}
              >
                <p>
                  I always think teenagers must hate my place as there is
                  nothing to do but relax and enjoy the sea view and sunsets and
                  read books which is not a very teenagery thing to do (my idea
                  of
                </p>
              </div>
            </div>
            <div className="seller-chat-activity-left">
              <div className="chat-user">
                <img id="chatimg" src={women} alt="" />
                <span>Norman Hernandez</span>
                <span>05:10 AM</span>
              </div>
              <div
                className={`chat-box-left chat-color-read  ${
                  expanded && "seller-chat-left-expanded"
                }`}
              >
                <p>
                  Hey y’all! We own Hidden I always think teenagers must hate my
                  place as there is nothing to do but relax and enjoy the sea
                  view and sunsets and read books which is not a very teenagery
                  thing to do (my idea of Lake Forest which is in a private lake
                  community. 🛶🏖
                </p>
                <img src={action} alt="" />
              </div>
            </div>

            <div className="seller-chat-activity-left">
              <div className="chat-user user-active">
                <img id="chatimg" src={women} alt="" />
                <span>Norman Hernandez</span>
                <span>Typing...</span>
              </div>
            </div>
          </div> */}

          {/* {userInformation?.roleTypes === 2 ? (
            <AdminChatContainer expanded={expanded} userType={'owner'} currentUserId={userInformation?._id} />
          ) : (
            <ChatContainer expanded={expanded} userType={'investor'} currentUserId={userInformation?._id} />
          )} */}
          <ChatContainer
            chats={chats}
            setChats={setChats}
            selectedChatId={selectedChatId}
            setSelectedChatId={setSelectedChatId}
            expanded={expanded}
            assetImgId={assetImgId}
            setParticipantsMembers={setParticipantsMembers}
          />
        </div>
      </div>
    </div>
  );
};

import "./SuccessTostify.scss";
import Success_tick from "../../../assets/Openai/Success_Tick.svg";
import {ReactComponent as CrossBtn} from "../../../assets/Openai/crossIcon.svg";
import ErrorIcon from "../../../assets/Openai/Error_Icon.svg";

export const TaskAssignedToast = ({ deadline, selectedUsers, onViewTasks }) => {
  const displayLimit = 2;
  const userNames = selectedUsers
    .slice(0, displayLimit)
    .map((user) => `${user.name} ${user.surname}`);

  const additionalCount = selectedUsers.length - displayLimit;

  return (
    <div className="tostify_alert_container">
      <div className="tostify_alert_wrapper">
        <button className="crossBtn">
          <CrossBtn />
        </button>
        <div className="tostify_alert_header">
          <div className="success_image_withText">
            <img
              src={Success_tick}
              alt="successTick"
            />
            <h4>Successful!</h4>
          </div>
          <p>Follow Up Set Successfully</p>
        </div>
        <div className="tostify_alert_body">
          <div className="dead_line_div">
            <p>Deadline:</p>
            <span>
              {new Date(deadline).toLocaleDateString("en-GB", {
                day: "numeric",
                month: "long",
                year: "numeric",
              })}
            </span>
          </div>
          <div className="dead_line_div">
            <p>Assigned to:</p>
            <span>
              {userNames.join(", ")}
              {additionalCount > 0 && ` +${additionalCount} more`}
            </span>
          </div>
        </div>
        <button
          className="viewBtn"
          onClick={onViewTasks}
        >
          View in Task Page
        </button>
      </div>
    </div>
  );
};


export const OpenAIErrorToast = ({errorMessage, onClose}) => (
  <div className="openai_error_container">
    <div className="openai_error_wrapper">
      <button
        className="crossBtn"
        onClick={onClose}
      >
        <CrossBtn />
      </button>
      <div className="openai_error_header">
        <div className="error_image_withText">
          <img
            src={ErrorIcon}
            alt="errorIcon"
          />
          <h4>Error!</h4>
        </div>
        <p>{errorMessage || "An unexpected error occurred."}</p>
      </div>
    </div>
  </div>
);

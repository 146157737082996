import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import axios from "axios";
import {fetchChecklists} from "../../store/reducers/checklist";
import InviteBox from "./Svgimages/InviteBox";
import BulkBox from "./Svgimages/BulkBox";
import FilterBox from "./Svgimages/FilterBox";
import "./ToDo.scss";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import ToDoListComponent from "./ToDoListComponent/ToDoListComponent";
import CircularProgress from "@mui/material/CircularProgress";
import filterIcon from "../../assets/checklistIcons/filterIcon.svg";
import addIcon from "../../assets/checklistIcons/addTaskIcon.svg";
import Endpoint from "../../utils/Endpoint";

const ToDo = () => {
  const [openInvite, setOpenInvite] = useState(false);
  const [openBulk, setOpenBulk] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [expandedTodo, setExpandedTodo] = useState(null);
  const [open, setOpen] = useState(false);
  const [checklistName, setChecklistName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const {idToken} = useSelector((state) => state.auth);
  const {checklist} = useSelector((state) => state.checklist);
  const {selectedProperty} = useSelector((state) => state.portfolio);
  const assetIddata = selectedProperty?._id;
  const handleInvite = () => setOpenInvite(!openInvite);
  const handleBulk = () => setOpenBulk(!openBulk);
  const handleFilter = () => setOpenFilter(!openFilter);
  const handleTodoExpand = (title) => setExpandedTodo(expandedTodo === title ? null : title);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (assetIddata) {
      dispatch(fetchChecklists(assetIddata));
    }
  }, [dispatch, assetIddata]);

  const handleAddChecklistAndClose = async () => {
    setIsLoading(true);
    try {
      const config = {
        headers: {
          "auth-token": idToken,
        },
      };
      const payload = {
        name: checklistName.trim(),
      };

      await axios.post(`${Endpoint.Addtodo}/${assetIddata}/checklists`, payload, config);
      dispatch(fetchChecklists(assetIddata));
    } catch (error) {

    }

    setChecklistName("");
    setOpen(false);
    setIsLoading(false);
  };

  const calculatePercentage = (completedTasks, totalTasks) => {
    return totalTasks > 0 ? ((completedTasks / totalTasks) * 100).toFixed(0) : 0;
  };

  useEffect(() => {
    checklist.forEach((checklistItem) => {
      const completedTasks = checklistItem?.tasks?.filter((task) => task?.completed).length;
      const totalTasks = checklistItem?.tasks?.length;
      const percentage = calculatePercentage(completedTasks, totalTasks);
      const className = `.checklist_${checklistItem.name.replace(/\s+/g, "_")}_progress_circle`;
      const gradientElement = document.querySelector(className);
      if (gradientElement) {
        gradientElement.style.background = `
          radial-gradient(closest-side, white 79%, transparent 80% 100%),
          conic-gradient(#7ec331 ${percentage}%, #d4d4d4 ${percentage}%)
        `;
      }
    });
  }, [checklist]);

  return (
    <div className="todo_wrapper">
      <div className="deals-room-center-header-ToDo">
        <div className="topHeader_components">
          <div className="progress_container">
            {checklist?.map((checklistItem) => {
              const completedTasks = checklistItem?.tasks?.filter((task) => task?.completed).length;
              const totalTasks = checklistItem?.tasks?.length;
              const percentage = calculatePercentage(completedTasks, totalTasks);
              const className = `checklist_${checklistItem.name.replace(/\s+/g, "_")}_progress`;

              return (
                <div
                  className={`${className} progress_box`}
                  key={checklistItem.name}
                >
                  <div className={`${className}_circle progress_circle`}>
                    <span className="progress"></span>
                    <p>{percentage}%</p>
                  </div>
                  <div className="progress_info">
                    <p className="progress_label">{checklistItem?.name}</p>
                    <p className="progress_ratio">
                      {completedTasks}/{totalTasks}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="todo_header">
        <div className="left">
          <h2>Checklists</h2>
        </div>
        <div
          style={{position: "relative"}}
          className="right"
        >
          <button>
            <img
              src={filterIcon}
              alt="filterIcon"
            />
            Filter
          </button>
          <button onClick={handleClickOpen}>
            <img
              src={addIcon}
              alt="addIcon"
            />
            Add CheckList
          </button>
        </div>
      </div>
      {openInvite && <InviteBox handleInvite={handleInvite} />}
      {openBulk && <BulkBox handleBulk={handleBulk} />}
      {openFilter && <FilterBox handleFilter={handleFilter} />}

      <div className="toDoListContainer">
        {checklist && checklist.length > 0 ? (
          <ToDoListComponent
            title={checklist.name}
            isExpanded={expandedTodo === checklist.name}
            onExpand={() => handleTodoExpand(checklist.name)}
            assetId={assetIddata}
            checklist={checklist}
          />
        ) : (
          <p className="No_checklists">No checklists available.</p>
        )}
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Add New Checklist</DialogTitle>
        <DialogContent className="dialog-content">
          <DialogContentText>Enter the name of the new checklist you want to add.</DialogContentText>
          <TextField
            margin="dense"
            label="Checklist Name"
            type="text"
            fullWidth
            value={checklistName}
            onChange={(e) => setChecklistName(e.target.value)}
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleAddChecklistAndClose}
            disabled={isLoading}
            startIcon={isLoading ? <CircularProgress size={20} /> : null}
          >
            {isLoading ? "Adding..." : "Add Checklist"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ToDo;

import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import ShimmerEffect from "../../../Layout/ShimmerEffect";
import {updateProperty} from "../../../store/reducers/portfolioSlice";
import {fetchPortfolio, selectProperty} from "../../../store/reducers/portfolioSlice";
import {PrimaryButton} from "../../../components/reusableComponents/Buttons/Buttons";
import CircularProgress from "@mui/material/CircularProgress";

const PropertyDetailsContent = ({selectedItem}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const dispatch = useDispatch();
  const userPermissions = useSelector((state) => state.AdminGivenAccess?.assets?.assetPermissions);
  const userInformation = useSelector((state) => state.auth?.userInformation);

  const hasEditPermission = (section, permissionKeys) => {
    const foundPermission = userPermissions?.find((perm) => perm?.section === section);
    return foundPermission ? permissionKeys.every((key) => foundPermission?.permissions[key] === true) : false;
  };

  useEffect(() => {
    if (userInformation?.email) {
      dispatch(fetchPortfolio(userInformation?.email));
    }
  }, [dispatch, userInformation?.email]);

  useEffect(() => {
    setIsLoading(!selectedItem);
  }, [selectedItem]);

  const canEdit = userInformation?.roleTypes === 2 || hasEditPermission("Property Data Overview", ["assetName", "assetDescription"]);

  const openPopup = () => {
    if (canEdit) {
      setIsPopupOpen(true);
    } else {
      alert("You do not have permission to edit this asset.");
    }
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const handleEdit = () => {
    openPopup();
  };

  if (isLoading) {
    return (
      <div className="property-details-card">
        <div className="detail-card-left-container shimmer-effect">
          <ShimmerEffect />
        </div>
      </div>
    );
  }

  return (
    <div className="property-details-card">
      <div className="detail-card-left-container">
        <div className="PropertyDetailsContent_AssetName">
          <h1>{selectedItem?.assetName}</h1>
          <div className="details_with_button">
            <h4>{selectedItem?.property_type}</h4>
            {canEdit && (
              <button
                className="edit-button"
                onClick={handleEdit}
              >
                Edit
              </button>
            )}
          </div>
        </div>
        <h2>{selectedItem?.assetsAddress}</h2>
        <p>{selectedItem?.assetDescription}</p>
      </div>
      <PropertyDetailsPopupEdit
        isOpen={isPopupOpen}
        onClose={closePopup}
        propertyData={selectedItem}
        dispatch={dispatch}
        selectedItem={selectedItem}
      />
    </div>
  );
};

const PropertyDetailsPopupEdit = ({isOpen, onClose, propertyData, refreshPropertyDetails}) => {
  const [editedPropertyData, setEditedPropertyData] = useState(propertyData);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const handleSave = async () => {
    setLoading(true);
    try {
      const {assetName, assetDescription} = editedPropertyData;

      await dispatch(
        updateProperty({
          propertyId: propertyData.id,
          updatedData: {assetName, assetDescription},
          selectedItem: propertyData,
        }),
      );

      onClose();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const {name, value} = e.target;
    setEditedPropertyData({...editedPropertyData, [name]: value});
  };

  const handleClose = (e) => {
    if (e.target.className.includes("popup_overlay_PropertyDetailsContent")) {
      onClose();
    }
  };

  useEffect(() => {
    setEditedPropertyData(propertyData);
  }, [propertyData]);

  return isOpen ? (
    <div
      className="popup_overlay_PropertyDetailsContent"
      onClick={handleClose}
    >
      <div className="popup_content_PropertyDetailsContent">
        <div className="modal-header">
          <h2>Edit Property Details</h2>
          <button
            className="close-button"
            onClick={onClose}
          >
            &#10005;
          </button>
        </div>
        <form>
          <label>Asset Name:</label>
          <input
            type="text"
            name="assetName"
            value={editedPropertyData.assetName}
            onChange={handleInputChange}
            placeholder="Asset Name"
          />
          <label>Asset Description:</label>
          <textarea
           className="TextAreaFont"
            name="assetDescription"
            value={editedPropertyData.assetDescription}
            onChange={handleInputChange}
            placeholder="Asset Description"
            style={{height: "200px"}}
          />
          <PrimaryButton
            name={
              loading ? (
                <CircularProgress
                  size={24}
                  color="inherit"
                />
              ) : (
                "Save Changes"
              )
            }
            className="save-button"
            type="button"
            onclick={handleSave}
          ></PrimaryButton>
        </form>
      </div>
    </div>
  ) : null;
};

export default PropertyDetailsContent;

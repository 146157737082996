export const donutChartOptions = {
  chart: {
    type: "donut",
  },
  labels: [],
  colors: ["#1976d2", "#d32f2f"],
  dataLabels: {
    enabled: false,
  },
  legend: {
    show: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["#fff"],
  },
  tooltip: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      donut: {
        size: "65%",
        labels: {
          show: false,
        },
      },
    },
  },
};

export const profitGrowthOptions = {
  chart: {
    type: "line",
    height: 100,
    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
  },
  xaxis: {
    labels: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
  },
  yaxis: {
    show: false,
  },
  grid: {
    show: false,
  },
  legend: {
    show: false,
  },
};

//   FinancialKPI

export const chartOptions = {
  chart: {
    id: "financial-chart",
    type: "area",
    height: "100%",
    background: "transparent",
    zoom: {
      enabled: true,
    },
    toolbar: {
      show: false,
      tools: {
        zoomin: true,
        zoomout: true,
        pan: true,
        reset: true,
      },
      offsetX: 0,
      offsetY: 0,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    width: 2,
  },
  xaxis: {
    type: "datetime",
    labels: {
      show: false,
    },
    axisBorder: {
      show: true,
      color: "#DDDDDD",
    },
    axisTicks: {
      show: true,
      color: "#DDDDDD",
    },
    tooltip: {
      enabled: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
    axisBorder: {
      show: true,
      color: "#DDDDDD",
    },
    axisTicks: {
      show: true,
      color: "#DDDDDD",
    },
    tooltip: {
      enabled: false,
    },
  },
  tooltip: {
    x: {
      format: "yyyy-MM-dd",
    },
    y: {
      formatter: (value) => `$${value}`,
    },
  },
  grid: {
    show: true,
    Color: "#6E47BD",
    strokeDashArray: 4,
    position: "back",
    row: {
      colors: undefined,
      opacity: 0.5,
    },
    column: {
      colors: undefined,
      opacity: 0.5,
    },
    padding: {
      top: 0,
      bottom: 0,
    },
  },
  annotations: {
    yaxis: [
      {
        y: 0,
        borderColor: "#6E47BD",
        borderWidth: 1,
        label: {
          show: false,
        },
      },
    ],
  },
};

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Endpoint from "../../../utils/Endpoint";

const initialState = {
  tenantsWithMoveOutDate2023: [],
  rentGrowth: "",
  totalRent: "",
  occupiedPercentage: "",
  latestDate: "",
  leasedOccupancy: "",
  physicalOccupancy: "",
  physicalVacancy: "",
  rentReadyCount: "",
  notRentReadyCount: "",
  totalCurrentLeaseRent: "",
  renewalRate:"",
  Renewal: [],
  mostProfitableRenters: [],
  status: "idle",
  error: null,
};

export const fetchMostProfitableRenters = createAsyncThunk(
  "tenantMetrics/fetchMostProfitableRenters",
  async ({ propertyCode, page, limit, idToken }, thunkAPI) => {
    try {
      const response = await axios.get(Endpoint.Most_Profitable_Renters, {
        headers: {
          "auth-token": idToken,
        },
        params: { property_code: propertyCode, page, limit },
        page,
        limit 
      });

      return response.data;
    } catch (error) {
      const errorMsg = error.response?.data || "Network error";
      return thunkAPI.rejectWithValue(errorMsg);
    }
  }
);

export const fetchPortfolioDashboard = createAsyncThunk(
  "tenantMetrics/fetchPortfolioDashboard",
  async ({ propertyCode, idToken }, thunkAPI) => {
    try {
      const response = await axios.get(Endpoint.KPI, {
        headers: {
          "auth-token": idToken,
        },
        params: { propertyCode },
      });

      const data = response.data;

      return {
        tenantsWithMoveOutDate2023: data.Lease_rental_growth || [],
        rentGrowth: data.rentGrowth,
        totalRent: data.Occupied || "",
        occupiedPercentage: data.occupiedPercentage || "",
        latestDate: data.latestDate || "",
        totalCurrentLeaseRent: data.totalCurrentLeaseRent || "",
        Renewal: data.Renewal || [],
        renewalRate:data.renewalRate
      };
    } catch (error) {
      const errorMsg = error.response?.data || "Network error";
      return thunkAPI.rejectWithValue(errorMsg);
    }
  }
);

export const fetchPropertyUnitBreakdown = createAsyncThunk(
  "tenantMetrics/fetchPropertyUnitBreakdown",
  async ({ propertyCode, idToken }, thunkAPI) => {
    try {
      const response = await axios.get(Endpoint.Unit_Breakdown, {
        headers: {
          "auth-token": idToken,
        },
        params: { propertyCode },
      });

      const data = response.data;

      return {
        leasedOccupancy: data.Leased_occupancy,
        physicalOccupancy: data.Physical_occupancy,
        physicalVacancy: data.Physical_vacancy,
        TOTAL_AVAILABLE_PERCENT: data.TOTAL_AVAILABLE_PERCENT,
        EIGHTEEN_MO_L_ROLL: data.EIGHTEEN_MO_L_ROLL,
      };
    } catch (error) {
      const errorMsg = error.response?.data || "Network error";
      return thunkAPI.rejectWithValue(errorMsg);
    }
  }
);

const tenantMetricsSlice = createSlice({
  name: "tenantMetrics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPortfolioDashboard.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchPortfolioDashboard.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.tenantsWithMoveOutDate2023 = action.payload.tenantsWithMoveOutDate2023;
        state.rentGrowth = action.payload.rentGrowth;
        state.totalRent = action.payload.totalRent;
        state.occupiedPercentage = action.payload.occupiedPercentage;
        state.latestDate = action.payload.latestDate;
        state.totalCurrentLeaseRent = action.payload.totalCurrentLeaseRent;
        state.Renewal = action.payload.Renewal;
        state.renewalRate = action.payload.renewalRate;
      })
      .addCase(fetchPortfolioDashboard.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch data";
      })
      .addCase(fetchPropertyUnitBreakdown.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchPropertyUnitBreakdown.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.leasedOccupancy = action.payload.leasedOccupancy;
        state.physicalOccupancy = action.payload.physicalOccupancy;
        state.physicalVacancy = action.payload.physicalVacancy;
        state.TOTAL_AVAILABLE_PERCENT = action.payload.TOTAL_AVAILABLE_PERCENT;
        state.EIGHTEEN_MO_L_ROLL = action.payload.EIGHTEEN_MO_L_ROLL;
      })
      .addCase(fetchPropertyUnitBreakdown.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch data";
      })
      .addCase(fetchMostProfitableRenters.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchMostProfitableRenters.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.mostProfitableRenters = action.payload;
      })
      .addCase(fetchMostProfitableRenters.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch data";
      });
  },
});

export default tenantMetricsSlice.reducer;

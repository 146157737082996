import {useSelector} from "react-redux";

const useAuthConfig = () => {
  const {idToken} = useSelector((state) => state.auth);

  return {
    headers: {
      "auth-token": idToken,
    },
  };
};

export default useAuthConfig;


// to use it  ----

// import useAuthConfig from './path/to/useAuthConfig';
// const config = useAuthConfig();

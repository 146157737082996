import {initializeApp} from "firebase/app";
import {getFirestore} from "firebase/firestore";
import {getMessaging} from "firebase/messaging";

// const firebaseConfig = {
//     apiKey: "AIzaSyCCg-TQarmSVdRQdvfWqm_7MQ7T_rZGoNc",
//     authDomain: "test-chat-7ec84.firebaseapp.com",
//     projectId: "test-chat-7ec84",
//     storageBucket: "test-chat-7ec84.appspot.com",
//     messagingSenderId: "640469834142",
//     appId: "1:640469834142:web:c6e5c6e4bdcc3259021e8f"
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyD9pKoS8SvFXpR3ub8K1DCjWBjC26hVyAo",
//   authDomain: "test-d82c4.firebaseapp.com",
//   databaseURL: "https://test-d82c4-default-rtdb.firebaseio.com",
//   projectId: "test-d82c4",
//   storageBucket: "test-d82c4.appspot.com",
//   messagingSenderId: "229245498869",
//   appId: "1:229245498869:web:60caf4b42997cc8cfd5cbc",
//   measurementId: "G-9EQGS71TJL",
// };

const firebaseConfig = {
  apiKey: "AIzaSyCijyyudGEEGk2h-Qf_2DceHDHMV41-qcE",
  authDomain: "lilypads-b19e9.firebaseapp.com",
  projectId: "lilypads-b19e9",
  storageBucket: "lilypads-b19e9.appspot.com",
  messagingSenderId: "526500719519",
  appId: "1:526500719519:web:b488823fe5a01684786baa"
};

const app = initializeApp(firebaseConfig);

const firestore = getFirestore(app);

const messaging = getMessaging(app);

export {firestore, messaging};

import React, {useMemo, useState, useRef} from "react";
import "./History.scss";
import {PrimaryButton, PrimaryButtonloading} from "../../../components/reusableComponents/Buttons/Buttons";
import information from "../../../assets/Success Popup/infomation_svg_icon.svg";
import Chart from "react-apexcharts";
import {useSelector} from "react-redux";
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, CircularProgress} from "@mui/material";
import axios from "axios";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Endpoint from "../../../utils/Endpoint";
import {donutChartOptions, profitGrowthOptions} from "../../../components/ApexChart/AllCharts";
import GPT from "../OpenAI/GPT";

const History = () => {
  const [budgetData, setBudgetData] = useState({data: [], totalItems: 0, totalPages: 0});
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const {selectedProperty} = useSelector((state) => state.portfolio);
  const property_code = selectedProperty?.Property_Code;
  const property_name = selectedProperty?.assetName;
  const {idToken} = useSelector((state) => state.auth);
  const [exportData, setExportData] = useState([]);
  const [file, setFile] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [fetchOrGenerate, setFetchOrGenerate] = useState("fetch");
  const [accountNumberFilter, setAccountNumberFilter] = useState("");
  const [accountNumbers, setAccountNumbers] = useState([]);
  const [BookTypes, setBookTypes] = useState([]);
  const [open, setOpen] = useState(false);
  const pageSize = 10;
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleUploadExcel = async () => {
    if (!file) {
      alert("Please select a file first.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("property_code", property_code);

    try {
      setLoading(true);
      const response = await axios.post("http://3.143.248.19:8000/upload-excel", formData, {
        // const response = await axios.post("http://localhost:8000/upload-excel", formData, {
        headers: {
          "x-api-key": "lilypads_khfikacvenvoedkjnvnwo_&^%$&jfowjf&^@%*^#*wbfhwrbfrskjnvs",
          "Content-Type": "multipart/form-data",
        },
        responseType: "blob",
      });

      if (response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${property_code}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        fetchBudgetData();
      }
    } catch (error) {
      alert(error.response?.data?.error || "File upload failed.");
    } finally {
      setLoading(false);
    }
  };

  // Dontu
  const donutChartData = [80, 20];
  const profitGrowthSeries = [
    {name: "Profit", data: [10, 15, 25, 20, 30, 32]},
    {name: "Profit/Expenses", data: [5, 8, 7, 10, 12, 9]},
  ];


  const handleCloseDialog = () => {
    setOpen(false);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type === "application/vnd.ms-excel")) {
      setFile(file);
    } else {
      alert("Please upload a valid Excel file (.xlsx or .xls).");
    }
  };

  const fetchBudgetData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(Endpoint.getbudgetdata, {
        headers: {
          "auth-token": idToken,
        },
        params: {
          property_code,
          page: currentPage,
          limit: pageSize,
          year: selectedYear || undefined,
          month: selectedMonth,
          accountNo: accountNumberFilter,
        },
      });

      if (response.data) {
        setBudgetData({
          data: response.data.data || [],
          totalItems: response.data.totalItems || 0,
          totalPages: response.data.totalPages || 0,
        });
        setExportData(response.data.data || []);
        setAccountNumbers(response.data.distinctAccountNumbers || []);
        setBookTypes(response.data.lastBookTimes || []);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="History_main_container">
      <div className="Predicted_data_container">
        <h2>{`Predicted Data for ${selectedProperty?.assetName}`}</h2>
        {/* <div className="Bugget_data_History">
          <PrimaryButton
            name="Download"
            classNames="BeartacticAI_History"
            padding="10px"
            svgHeight="20px"
            svgWidth="20px"
          />
          <PrimaryButtonloading
            name="Upload Excel"
            onClick={handleOpenDialog}
            classNames="NewBudgetTable_Button"
            disabled={loading}
            padding="10px"
            svgHeight="20px"
            svgWidth="20px"
          />

          <PrimaryButton
            name="Generate Budget"
            classNames="BeartacticAI_History"
            padding="10px"
            svgHeight="20px"
            svgWidth="20px"
          />
        </div> */}
      </div>

      <div className="predicted-data-container">
        <div className="left-section">
          <div className="data-card combined-card">
            <div className="section no-action">
              <div className="card_infomation_withimage">
                <h3>Needs No Action</h3>
                <img
                  src={information}
                  alt="image"
                />
              </div>
              <p className="paragraphtext_forBuggetting">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua . Ut enim ad minim veniam. Lorem ipsum dolor sit amet,
                consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua . Ut enim ad minim veniam.
              </p>
            </div>
            <hr className="divider" />
            <div className="section needs-change">
              <div className="card_infomation_withimage">
                <h3>Needs Change</h3>
                <img
                  src={information}
                  alt="image"
                />
              </div>
              <p className="paragraphtext_forBuggetting">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua . Ut enim ad minim veniam. Lorem ipsum dolor sit amet,
                consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua . Ut enim ad minim veniam.
              </p>
            </div>
            <hr className="divider" />
            <div className="section suggestions">
              <div className="card_infomation_withimage">
                <h3>Suggestions</h3>
                <img
                  src={information}
                  alt="image"
                />
              </div>
              <p className="paragraphtext_forBuggetting">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua . Ut enim ad minim veniam. Lorem ipsum dolor sit amet,
                consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua . Ut enim ad minim veniam.
              </p>
            </div>
            <hr className="divider" />
            <div className="section prediction">
              <div className="section suggestions">
                <div className="card_infomation_withimage">
                  <h3>Prediction</h3>
                  <img
                    src={information}
                    alt="image"
                  />
                </div>
              </div>

              <div className="invoice-info">
                <div className="invoice-chart">
                  <h3>Invoice</h3>
                  <Chart
                    options={donutChartOptions}
                    series={donutChartData}
                    type="donut"
                    width="320"
                    height={250}
                  />
                </div>
                <div className="invoice-details-for-BeartacticAI">
                  <div>
                    <h4>$1,123,595</h4>
                    <p>Invoice for 2025</p>
                  </div>
                  <div className="total_amonuts_data">
                    <div className="amount_tobepaid">
                      <p>Paid: </p>
                      <span>$10,095</span>
                    </div>
                    <div className="amount_tobepaid">
                      <p>Unpaid:</p>
                      <span>$2,500</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="profit-growth-section">
                <h4>Net Profit Growth</h4>
                <div className="growth-container">
                  <div className="growth-chart">
                    <Chart
                      options={profitGrowthOptions}
                      series={profitGrowthSeries}
                      type="line"
                      height={100}
                    />
                    <Chart
                      options={profitGrowthOptions}
                      series={profitGrowthSeries}
                      type="line"
                      height={100}
                    />
                  </div>
                  <div className="growth-data">
                    <div className="data-box">
                      <h2 className="growth-percentage">32%</h2>
                      <p className="growth-text">6 Months Increase</p>
                    </div>
                    <div className="data-box">
                      <h2 className="growth-percentage">9%</h2>
                      <p className="growth-text">Yearly Increase</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <GPT />
      </div>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
      >
        <DialogContent
          style={{
            padding: "20px",
            textAlign: "center",
            width: "540px",
          }}
        >
          {!uploadProgress && (
            <Typography
              variant="h6"
              style={{
                marginBottom: "16px",
              }}
            >
              Upload Excel File
            </Typography>
          )}
          <div
            className="NewBudgetTable-drop_box"
            style={{
              border: "1px dashed #ccc",
              padding: "20px",
              borderRadius: "8px",
              backgroundColor: "#f9f9f9",
              marginBottom: "16px",
            }}
          >
            <header>
              <Typography
                variant="body2"
                color="textSecondary"
                style={{marginBottom: "12px"}}
              >
                {file ? `Selected file: ${file.name}` : "No file selected"}
              </Typography>
            </header>
            <Typography
              variant="body2"
              style={{
                marginBottom: "12px",
              }}
            >
              Files Supported: XLSX
            </Typography>
            <Button
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
              disabled={loading}
              style={{
                marginBottom: "16px",
                backgroundColor: "#3f51b5",
                color: "#fff",
              }}
            >
              Select File
              <input
                type="file"
                onChange={handleFileChange}
                accept=".xlsx, .xls"
                style={{display: "none"}}
              />
            </Button>
            {uploadProgress > 0 ? (
              <div style={{marginTop: "16px"}}>
                <CircularProgress
                  variant="determinate"
                  value={uploadProgress}
                />
              </div>
            ) : null}
          </div>
        </DialogContent>
        <DialogActions style={{justifyContent: "center", padding: "12px"}}>
          <Button
            onClick={handleCloseDialog}
            color="primary"
            disabled={loading}
            style={{
              marginRight: "8px",
              backgroundColor: "#f1f1f1",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleUploadExcel}
            color="primary"
            variant="contained"
            disabled={loading || !file}
            style={{
              backgroundColor: "#3f51b5",
              color: "#fff",
            }}
          >
            {loading ? (
              <CircularProgress
                size={24}
                color="inherit"
              />
            ) : (
              "Upload"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default History;

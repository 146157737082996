import React, {useState, useEffect} from "react";
import "./NewHeader.scss";
import {Link, useLocation, useNavigate} from "react-router-dom";
import logo from "../../assets/Image/Logo.png";
import menu_icon from "../../assets/Image/menu_icon.png";
import cross_icon from "../../assets/Image/cross_icon.png";
import {useSelector, useDispatch} from "react-redux";
import {fetchUserDetails, setIsChatOpen} from "../../store/reducers/userDetailsSlice";
import {logout} from "../../store/reducers/authSlice";
import {PrimaryButton} from "../reusableComponents/Buttons/Buttons";
import headerCompare from "../../assets/svg/header-compare.svg";
import headerMessage from "../../assets/svg/header-message.svg";
import IncDev from "../../assets/svg/incdec.svg";
import ChatDropdown from "./ChatDropdown";
import profile from "../../assets/profile.png";
import {clearPersistedData} from "../../store/store";
import Task from "../../assets/Openai/Task.svg";
import Deals from "../../assets/Openai/deals.svg";
import Integration from "../../assets/Openai/Integration.svg";

const Header = () => {
  const [showSideNav, setShowSidenav] = useState(false);
  const [chatDropdown, setChatDropdown] = useState(false);
  const navigate = useNavigate();
  const {notificationCount, isChatopen} = useSelector((state) => state.userInfo);
  const dispatch = useDispatch();
  const location = useLocation();
  const {isAuthenticated, userInformation, idToken} = useSelector((state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    userInformation: state.auth.userInformation,
    idToken: state.auth.idToken,
  }));
  const [unseenMessages, setUnSeenMessages] = useState([]);

  const handleChat = () => {
    setChatDropdown(!chatDropdown);
    dispatch(setIsChatOpen());
  };

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchUserDetails(idToken));
    }
  }, [dispatch, idToken, isAuthenticated]);

  useEffect(() => {
    setUnSeenMessages(notificationCount?.length > 0 && notificationCount?.filter((item) => item?.seen === false));
  }, [notificationCount]);

  const handleLogout = () => {
    dispatch(logout());
    clearPersistedData();
    navigate("/");
  };

  return (
    <div className="headerO">
      <div className="headerO_CON">
        <div className="headerO-L">
          <img
            src={logo}
            alt="Logo"
            onClick={() => {
              navigate("/");
            }}
          />
          <svg
            width="1"
            height="38"
            viewBox="0 0 1 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="1"
              height="38"
              fill="#EAEDF3"
            />
          </svg>
          <div className={showSideNav ? "header-tabs side_nav" : "header-tabs"}>
            {showSideNav && (
              <img
                src={cross_icon}
                width="25px"
                alt="Close"
                style={{position: "absolute", top: "40px", left: "10px"}}
                onClick={() => setShowSidenav(false)}
              />
            )}
            {/* <Link
              to="/"
              className={location.pathname === "/" ? "active" : ""}
            >
              <img
                src={home}
                alt="Home Icon"
              />
              <p>Home</p>
            </Link> */}
            {isAuthenticated && (
              <>
                {/* {userInformation?.roleTypes !== 2 && userInformation?.roleTypes !== 3 && userInformation?.roleTypes === 4 && (
                  <Link
                    to="/investment-pipeline"
                    className={location.pathname === "/investment-pipeline" ? "active" : ""}
                  >
                    Investment Pipeline
                  </Link>
                )}
                {(userInformation?.roleTypes === 2 || userInformation?.roleTypes === 3) && (
                  <Link
                    to={`/${userInformation?._id}/portfolio`}
                    className={location.pathname === `/${userInformation?._id}/portfolio` ? "active" : ""}
                  >
                    Portfolio
                  </Link>
                )}
                {(userInformation?.roleTypes === 2 || userInformation?.roleTypes === 3) && (
                  <Link
                    to={`/${userInformation?._id}/asset-discovery`}
                    className={location.pathname === `/${userInformation?._id}/asset-discovery` ? "active" : ""}
                  >
                    Asset Discovery
                  </Link>
                )}
                {userInformation?.roleTypes === 4 && (
                  <Link
                    to="/asset-discovery?cf=true"
                    className={location.pathname === "/asset-discovery" ? "active" : ""}
                  >
                    Crowdfunding
                  </Link>
                )} */}

                {/* <Link
                  to="/opportunities"
                  className={location.pathname === "/opportunities" ? "active" : ""}
                >
                  Opportunities
                </Link> */}
                {/* {(userInformation?.roleTypes === 2 || userInformation?.roleTypes === 4) && (
                  <Link
                    to={`/${userInformation?._id}/deals`}
                    className={location.pathname === `/${userInformation?._id}/deals` ? "active" : ""}
                  >
                    Deals
                  </Link>
                )} */}
                {/* <Link
                  to={`/${userInformation?._id}/tasks`}
                  className={location.pathname === `/${userInformation?._id}/tasks` ? "active" : ""}
                >
                  Tasks
                </Link> */}
              </>
            )}
            {userInformation?.roleTypes === 2 && (
              <Link
                to={`/${userInformation?._id}/${userInformation?.roleTypes}/LilypadsAI`}
                className={location.pathname === `/${userInformation?._id}/${userInformation?.roleTypes}/LilypadsAI` ? "active" : ""}
              >
                Lilypads AI
              </Link>
            )}
          </div>
        </div>

        <div className="headerO-R">
          {isAuthenticated ? (
            <>
              {/* <Search /> */}
              <div className="header-info">
                <div className="leftComp">
                  <div className="message_icon_wrapper">
                    <Link to={`/${userInformation?._id}/deals`}>
                      <div className="message_icon">
                        {/* <p className="msg_notify">{unseenMessages?.length}</p> */}
                        <img
                          src={Deals}
                          alt="Message"
                        />
                      </div>
                      <p className="message_caption">Deal</p>
                    </Link>
                  </div>
                  <div className="message_icon_wrapper">
                    <Link to={`/${userInformation?._id}/tasks`}>
                      <div className="message_icon">
                        {/* <p className="msg_notify">{unseenMessages?.length}</p> */}
                        <img
                          src={Task}
                          alt="Message"
                        />
                      </div>
                      <p className="message_caption">Task</p>
                    </Link>
                  </div>

                  {/* <div className="compare_icon_wrapper">
                    <Link to="/compare">
                      <img
                        src={headerCompare}
                        alt="Compare"
                      />
                    </Link>
                    <p className="compare_caption">Compare</p>
                  </div> */}


                  <div className="message_icon_wrapper">
                    <div className="message_icon">
                      {/* <p className="msg_notify">{unseenMessages?.length}</p> */}
                      <img
                        onClick={handleChat}
                        src={headerMessage}
                        alt="Message"
                      />
                    </div>
                    <p className="message_caption">Chat</p>
                  </div>
                </div>

                {/* <div className="midComp">
                  <div className="compare_icon_wrapper">
                    <Link to="/compare">
                      <img
                        src={Integration}
                        alt="Compare"
                      />
                    </Link>
                    <p className="compare_caption">Integration</p>
                  </div>
                </div> */}

                <div className="rightComp">
                  <Link to={`/profile/${userInformation?._id}/${userInformation?.roleTypes}`}>
                    <div className="profile-details">
                      <div className="header_profile">
                        {userInformation?.profile_image ? (
                          <img
                            src={userInformation?.profile_image}
                            alt="Profile"
                            className="profile-pic"
                            style={{borderRadius: "50%"}}
                          />
                        ) : (
                          <img
                            src={profile}
                            alt="Default Profile"
                            className="profile-pic"
                            style={{borderRadius: "50%"}}
                          />
                        )}
                      </div>
                      <h6>
                        {`${userInformation?.name} ${userInformation?.surname}`} <br />
                        <span>{userInformation?.role}</span>
                      </h6>
                      <img
                        src={IncDev}
                        alt="Increase/Decrease"
                      />
                    </div>
                  </Link>
                </div>
              </div>
            </>
          ) : (
            <>
              <Link to="/signin">
                <p>Sign In</p>
              </Link>
              <Link to="/signup/investor">
                <PrimaryButton name="Sign up" />
              </Link>
              <div className="menu_icon_sideNav">
                <img
                  src={menu_icon}
                  alt="Menu"
                  width="25px"
                  onClick={() => setShowSidenav(true)}
                />
              </div>
            </>
          )}
        </div>
      </div>
      {isChatopen && <ChatDropdown handleChat={handleChat} />}
    </div>
  );
};

export default Header;

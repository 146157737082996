import axios from "axios";
import React, {useEffect, useRef, useState} from "react";
import ApexCharts from "react-apexcharts";
import ReactMarkdown from "react-markdown";
import {useDispatch, useSelector} from "react-redux";
import SeletectAssets from "../../assets/Openai/Chatbackground.png";
import gptLogo from "../../assets/Openai/logoforgpt.svg";
import Attachment from "../../assets/Openai/New_Attachment.svg";
import sendButton from "../../assets/Openai/send_button.png";
import userIcon from "../../assets/Openai/userlogoforgpt.svg";
import {fetchMessagesForChat} from "../../store/reducers/OpenAIChat/OpenAICHAT";
import {refreshChat} from "../../store/reducers/OpenAIChat/OpenAICHAT";
import Endpoint from "../../utils/Endpoint";
import "./ChatLeft.scss";
import KeywordMessage from "./KeyWord/KeyWords";
import {fetchChats} from "../../store/reducers/OpenAIChat/OpenAICHAT";
import useAuthConfig from "../../utils/Config";

const ChatLeft = ({selectedChat, onNewMessage, isNewChatLoading, setSelectedChat, setIsClicked}) => {
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openAssignPopup, setOpenAssignPopup] = useState(false);
  const {idToken, userInformation} = useSelector((state) => state.auth);
  const [typingMessage, setTypingMessage] = useState(null);
  const chatEndRef = useRef(null);
  const [userlist, setUserList] = useState([]);
  const [isOldChat, setIsOldChat] = useState(false);
  const dispatch = useDispatch();
  const [menuVisibleIndex, setMenuVisibleIndex] = useState(null);
  const config = useAuthConfig()
  const initialLoad = useRef(true);
  const keywords = ["Actionable Recommendations:", "Insights:", "Summary:", "Analysis:", "Recommendations", "recommendations:"];
  const [isAnimating, setIsAnimating] = useState(isNewChatLoading);
  const {selectedProperty} = useSelector((state) => state.portfolio);

  const detectKeyword = (text) => {
    if (text.includes("Recommendations")) {
      return "Recommendations";
    }

    const detected = keywords.find((keyword) => text.includes(keyword));
    return detected;
  };

  const handleAssignToTeam = () => {
    setOpenAssignPopup(true);
  };

  useEffect(() => {
    if (isNewChatLoading) {
      setIsAnimating(true);
    } else {
      setTimeout(() => {
        setIsAnimating(false);
      }, 2000);
    }
  }, [isNewChatLoading]);

  useEffect(() => {
    if (initialLoad.current && selectedChat?.id) {
      initialLoad.current = false;

      dispatch(fetchMessagesForChat({asset_id: selectedChat?.id, idToken}))
        .unwrap()
        .then((response) => {
          setMessages((prevMessages) => {
            const newMessages = response?.messages?.data || [];
            const uniqueMessages = newMessages?.filter((msg) => !prevMessages?.some((prevMsg) => prevMsg?.createdAt === msg.timestamp));
            return [...prevMessages, ...uniqueMessages];
          });
        })
        .catch((error) => console.error("Failed to fetch messages:", error));
    }
  }, [selectedChat, dispatch, idToken]);

  useEffect(() => {
    if (selectedChat) {
      setMessages(selectedChat?.messages || []);
      dispatch(fetchMessagesForChat({asset_id: selectedChat?.id, idToken}))
        .unwrap()
        .then((response) => {
          setTimeout(() => {
            setIsAnimating(false);
            setMessages(response?.messages?.data || []);
            setIsOldChat(true);
          }, 5000);
        })
        .catch((error) => {
          setIsAnimating(false);
        });
    } else {
      setIsOldChat(false);
      setIsAnimating(false);
    }
  }, [selectedChat, dispatch, idToken]);

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({behavior: "smooth"});
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, typingMessage]);

  const sendMessage = async () => {
    if (!userInput.trim()) return;

    const newMessage = {
      sender: "User",
      text: userInput.trim(),
      createdAt: new Date().toISOString(),
    };

    setUserInput("");

    setMessages((prev) => [...prev, newMessage]);

    setIsLoading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
      };

      const response = await axios.post(
        Endpoint.continuechat,
        {
          user_id: userInformation?._id,
          chat_id: selectedChat?.chatId,
          message: newMessage?.text,
          property_code: selectedProperty?.Property_Code,
        },
        config,
      );

      if (response.data?.data) {
        const botMessages = response.data.data.map((msg) => ({
          sender: msg.sender,
          text: msg.text,
          createdAt: msg.timestamp,
        }));

        setMessages((prev) => prev.filter((msg) => msg.createdAt !== newMessage.createdAt).concat(botMessages));

        if (onNewMessage) {
          botMessages.forEach((msg) => onNewMessage(selectedChat?.id, msg));
        }
      } else {
        setMessages((prev) =>
          prev
            .filter((msg) => msg.createdAt !== newMessage.createdAt)
            .concat({
              sender: "OpenAI",
              text: "No response from bot",
              createdAt: new Date().toISOString(),
            }),
        );
      }
    } catch (error) {
      setMessages((prev) =>
        prev
          .filter((msg) => msg.createdAt !== newMessage.createdAt)
          .concat({
            sender: "System",
            text: "Failed to get response. Please try again later.",
            createdAt: new Date().toISOString(),
          }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const renderChart = (data) => {
    if (data.pie) {
      return (
        <ApexCharts
          type="pie"
          series={data.pie.map((item) => item?.percentage)}
          options={{
            labels: data.pie.map((item) => item?.category),
          }}
          height={300}
        />
      );
    }

    if (data.line) {
      return (
        <ApexCharts
          type="line"
          series={[
            {name: "Average Rent", data: data?.line?.avg_rent},
            {name: "Occupancy Rate", data: data?.line?.occupancy_rate},
          ]}
          options={{
            xaxis: {categories: data.line.dates},
          }}
          height={300}
        />
      );
    }

    return null;
  };

  const handleRegenChat = () => {
    if (!selectedChat?.id) return;

    const requestData = {
      user_id: userInformation?._id,
      asset_id: selectedProperty?._id,
      chat_id: selectedChat?.chatId,
      property_code: selectedProperty?.Property_Code,
      idToken,
    };

    dispatch(refreshChat(requestData))
      .unwrap()
      .then(() => {
        dispatch(fetchChats({userId: userInformation?._id, assetId: selectedProperty?._id, idToken}))
          .unwrap()
          .then((chats) => {
            if (chats?.length > 0) {
              const firstChat = chats[0];
              setSelectedChat(firstChat);
              setIsClicked(1);
              dispatch(fetchMessagesForChat({ChatId: firstChat.id, asset_id: selectedProperty?._id, idToken}))
                .unwrap()
                .then((response) => {
                  setSelectedChat((prev) => ({
                    ...prev,
                    messages: response.messages || [],
                  }));
                })
                .catch((error) => {
                  console.error("Failed to fetch messages for the first chat:", error);
                });
            }
          })
          .catch((error) => {
            console.error("Failed to fetch chats after refresh:", error);
          });
      })
      .catch((error) => {
        console.error("Failed to refresh chat:", error);
      });
  };

  return (
    <div
      className="StandAloneBeartacticAI-chat-container"
      style={{
        backgroundImage: `url(${SeletectAssets})`,
        backgroundSize: "cover",
      }}
    >
      {isAnimating ? (
        <div className="StandAloneBeartacticAI-loading-screen">
          <span className="loader">BearTasktic AI is thinking and analyzing...</span>
        </div>
      ) : (
        <div className="StandAloneBeartacticAI-chat-content fade-in">
          <div className="StandAloneBeartacticAI-chat-messages-container">
            <div className="StandAloneBeartacticAI-chat-messages">
              {Array?.isArray(messages) &&
                messages
                  .filter((_, index) => !(index === 0 && messages[0]?.sender === "User"))
                  .map((msg, index) => {
                    const isReceived = msg.sender !== "User";
                    const detectedKeyword = detectKeyword(msg.text);

                    return (
                      <div
                        key={index}
                        className={`StandAloneBeartacticAI_message ${isReceived ? "received" : "sent"}`}
                      >
                        <img
                          src={isReceived ? gptLogo : userIcon}
                          alt={msg.sender}
                        />

                        {detectedKeyword ? (
                          <KeywordMessage
                            message={msg}
                            keyword={detectedKeyword}
                            index={index}
                            menuVisibleIndex={menuVisibleIndex}
                            onMenuToggle={setMenuVisibleIndex}
                            onAssignToTeam={handleAssignToTeam}
                            msg_id={msg?.msg_id}
                            handleRegenChat={handleRegenChat}
                          />
                        ) : (
                          <div className={`message-bubble ${isReceived ? "purple-background" : "default-background"}`}>
                            <ReactMarkdown>{msg.text}</ReactMarkdown>
                            {(() => {
                              try {
                                const parsedData = JSON.parse(msg.text);
                                return renderChart(parsedData);
                              } catch {
                                return null;
                              }
                            })()}
                          </div>
                        )}
                      </div>
                    );
                  })}

              {isLoading && (
                <div className="StandAloneBeartacticAI_message received typing-indicator">
                  <img
                    src={gptLogo}
                    alt="Typing Indicator"
                  />
                  <div className="message-bubble purple-background">
                    <div class="typing-indicator">
                      <div class="typing-dots">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div ref={chatEndRef} />
            </div>
          </div>

          <div className="ChatLeft_chat-input">
            <input
              type="text"
              placeholder="Ask Your Queries..."
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && sendMessage()}
              className="Text_Inputforchat"
            />
            <img
              src={Attachment}
              alt="Attach"
              className="send_button"
            />
            <button
              onClick={sendMessage}
              className="sendchat"
            >
              <img
                src={sendButton}
                alt="Send"
                className="sendchat"
              />
            </button>
          </div>
          <div className="ChatLeft_chat_details">
            <p>
              *<span>Lilypads</span> Can make mistake. Check important info
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatLeft;

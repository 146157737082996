import React, {useState, useEffect} from "react";
import arrowup from "../../../assets/Image/assetDiscovery/arrow_down.svg";
import Checkbox from "../../profile/settings/Checkbox";
import "./TimeTableBar.scss";

export const TimeTableBar = ({getTaskdata}) => {
  const [checkedState, setCheckedState] = useState(getTaskdata ? getTaskdata.map(() => false) : []);

  const handleCheckboxChange = (position) => {
    const updatedCheckedState = checkedState?.map((item, index) => (index === position ? !item : item));
    setCheckedState(updatedCheckedState);
  };

  const handleFixCheckboxes = () => {
    setCheckedState(getTaskdata?.map(() => true));
  };

  const getTaskTimeDisplay = (task) => {
    const date = new Date(task?.date);
    return task?.date && task?.time ? `${task.time.start} — ${task.time.end}` : "all day";
  };

  const [linePosition, setLinePosition] = useState(0);

  useEffect(() => {
    const updateTimelinePosition = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const totalMinutes = hours * 60 + minutes;

      const position = ((totalMinutes - 360) / 60) * 60;
      setLinePosition(position);
    };

    updateTimelinePosition();

    const interval = setInterval(updateTimelinePosition, 60000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="timetable_bar">
      <div className="timetable_bar_header">
        <button onClick={handleFixCheckboxes}>
          <img
            src={arrowup}
            alt="image"
            style={{rotate: "90deg"}}
          />
          Today
          <img
            src={arrowup}
            alt="image"
            style={{rotate: "270deg"}}
          />
        </button>
      </div>
      <div className="timetable_bar_agenda">
        <span className="title">Agenda</span>
        <div className="agenda_list">
          {getTaskdata && getTaskdata.length > 0 ? (
            getTaskdata.map((task, index) => (
              <div
                key={task?._id}
                className="agenda_item"
              >
               
                <div
                  key={task?._id}
                  className="agenda_item"
                >
                 <div className="TimeTableBar_agenda_item">
                 <div
                    className="agenda_indicator"
                    style={{backgroundColor: task?.color || getIndicatorColor(index)}}
                  />
                  <div className="task_time">
                    <p>{task?.time}</p>
                  </div>
                 </div>
                  <Checkbox
                    label={checkedState[index] ? <s>{task?.Name}</s> : task?.Name}
                    checked={checkedState[index]}
                    onChange={() => handleCheckboxChange(index)}
                  />
                </div>
              </div>
            ))
          ) : (
            <p>No tasks available</p>
          )}
        </div>
      </div>

      <div className="timetable_bar_details">
        <div className="timetable-container">
          <h3 className="timetable-title">TIMETABLE</h3>
          <div className="time-slot">
            <div className="time-row">
              <span className="time">6 AM</span>
              <div className="timetable_bar_divider" />
            </div>

            <div className="time-row">
              <span className="time">7 AM</span>
              <div className="timetable_bar_divider" />
            </div>

            <div className="time-row">
              <span className="time">8 AM</span>
              <div className="timetable_bar_divider" />
            </div>

            <div className="time-row">
              <span className="time">9 AM</span>
              <div className="timetable_bar_divider" />
            </div>

            <div className="time-row">
              <span className="time">10 AM</span>
              <div className="timetable_bar_divider" />
            </div>

            <div className="time-row">
              <span className="time">11 AM</span>
              <div className="timetable_bar_divider" />
            </div>

            <div className="time-row">
              <span className="time">12 PM</span>
              <div className="timetable_bar_divider" />
            </div>

            <div className="time-row">
              <span className="time">1 PM</span>
              <div className="timetable_bar_divider" />
            </div>

            <div className="time-row">
              <span className="time">2 PM</span>
              <div className="timetable_bar_divider" />
            </div>

            <div className="time-row">
              <span className="time">3 PM</span>
              <div className="timetable_bar_divider" />
            </div>

            <div className="time-row">
              <span className="time">4 PM</span>
              <div className="timetable_bar_divider" />
            </div>

            <div className="time-row">
              <span className="time">5 PM</span>
              <div className="timetable_bar_divider" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const getIndicatorColor = (index) => {
  const colors = ["green", "purple", "orange"];
  return colors[index % colors.length];
};

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Endpoint from "../../../utils/Endpoint";


export const fetchUnitTurnoverData = createAsyncThunk(
  'unitTurnover/fetchUnitTurnoverData', 
  async ({ property_code, page, limit }, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState();
      const idToken = auth?.idToken;

      const response = await axios.get(Endpoint.fetchUnitTurnoverData, {
        headers: {
          'auth-token': idToken,
        },
        params: {
          property_code,
          page,
          limit,
        },
      });

      return response.data;
    } catch (error) {
      const errorMsg = error.response?.data?.message || error.message || "Failed to fetch unit turnover data";
      return rejectWithValue(errorMsg);
    }
  }
);


const unitTurnoverSlice = createSlice({
  name: "unitTurnover",
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUnitTurnoverData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUnitTurnoverData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchUnitTurnoverData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default unitTurnoverSlice.reducer;

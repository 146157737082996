import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import Endpoint from "../../../utils/Endpoint";

export const fetchChats = createAsyncThunk("chat/fetchChats", async ({userId, assetId, idToken}, {rejectWithValue}) => {
  try {
    const config = {
      headers: {"auth-token": idToken},
    };
    const response = await axios.get(`${Endpoint.GetAllChatsForUser}?user_id=${userId}&asset_id=${assetId}`, config);
    return response.data.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const startNewChat = createAsyncThunk("chat/startNewChat", async ({userId, assetId, propertyCode, idToken}, {rejectWithValue}) => {
  try {
    const config = {
      headers: {Authorization: `Bearer ${idToken}`},
    };
    const data = {user_id: userId, asset_id: assetId, property_code: propertyCode};
    const response = await axios.post(Endpoint.StartChat, data, config);
    return response.data.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || "Failed to start chat");
  }
});

export const fetchMessagesForChat = createAsyncThunk("chat/fetchMessagesForChat", async ({ChatId, asset_id, idToken}, {rejectWithValue}) => {
  if (!ChatId || !asset_id) {
    return rejectWithValue("ChatId or asset_id is missing");
  }

  try {
    const config = {
      headers: {"auth-token": idToken},
    };
    const response = await axios.get(`${Endpoint.getmessages}?ChatId=${ChatId}&asset_id=${asset_id}`, config);
    return {asset_id, messages: response.data.data};
  } catch (error) {
    return rejectWithValue(error.response?.data || "Failed to fetch messages");
  }
});

export const continueChat = createAsyncThunk("chat/continueChat", async ({chatId, message, idToken}, {rejectWithValue}) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
    };

    const data = {chatId, message};
    const response = await axios.post(Endpoint.continuechat, data, config);

    return {chatId, message: response.data};
  } catch (error) {
    return rejectWithValue(error.response?.data || "Failed to continue chat");
  }
});

export const refreshChat = createAsyncThunk("chat/refreshChat", async (requestData, {rejectWithValue}) => {
  try {
    const {idToken, ...body} = requestData;
    const config = {
      headers: {Authorization: `Bearer ${idToken}`},
    };

    const response = await axios.post(Endpoint.refreshchat, body, config);

    return {refreshedChat: response.data};
  } catch (error) {
    return rejectWithValue(error.response?.data || "Failed to refresh chat");
  }
});

const chatSlice = createSlice({
  name: "chat",
  initialState: {
    chats: [],
    messages: {},
    activeChatId: null,
    loading: false,
    error: null,
  },
  reducers: {
    setActiveChat: (state, action) => {
      const chatId = action.payload;
      state.activeChatId = chatId;
      state.messages = {};
    },
    addMessageToChat: (state, action) => {
      const {chatId, message} = action.payload;

      if (!Array.isArray(state.messages[chatId])) {
        state.messages[chatId] = [];
      }

      state.messages[chatId].push(message);
    },
    clearChats: (state) => {
      state.chats = [];
      state.messages = {};
      state.activeChatId = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMessagesForChat.fulfilled, (state, action) => {
        const {asset_id, messages} = action.payload;
        if (state.activeChatId === asset_id) {
          state.messages[asset_id] = Array.isArray(messages.data) ? messages.data : [];
        }
      })
      .addCase(fetchChats.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchChats.fulfilled, (state, action) => {
        state.loading = false;
        state.chats = action.payload || [];
      })
      .addCase(fetchChats.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(startNewChat.fulfilled, (state, action) => {
        state.chats.unshift(action.payload);
        state.activeChatId = action.payload.id;
        state.messages[action.payload.id] = [];
      })
      .addCase(refreshChat.fulfilled, (state, action) => {
        const {refreshedChat} = action.payload;
        const chatIndex = state.chats.findIndex((chat) => chat.id === refreshedChat.id);
        if (chatIndex !== -1) {
          state.chats[chatIndex] = refreshedChat;
        }
      })

      .addCase(refreshChat.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export const {setActiveChat, addMessageToChat, clearChats} = chatSlice.actions;
export default chatSlice.reducer;

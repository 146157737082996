import React, { useState } from "react";
import "./Deals.scss";
import syndicatebtn from "../../assets/svg/syndicatebtn.svg";
import reqloanbtn from "../../assets/svg/reqloanbtn.svg";
import importbtn from "../../assets/svg/import.svg";
import bulk from "../../assets/svg/bulk.svg";
import { Link } from "react-router-dom";


const DealFilter = () => {
    return (
      <div className="add-deal-filter-wrapper-main">
        <Link to={"/newsyndicate"}>
          <div>
            <img
              src={syndicatebtn}
              alt=""
            />
            <span>Start a Syndicate</span>
          </div>
        </Link>
        <Link to={"/newloanreq"}>
          <div>
            <img
              src={reqloanbtn}
              alt=""
            />
            <span>Request a Loan</span>
          </div>
        </Link>
        <div className="divider"></div>
        <Link to={""}>
          <div>
            <img
              src={importbtn}
              alt=""
            />
            <span>Import a single Deal</span>
          </div>
        </Link>
        <Link to={""}>
          <div>
            <img
              src={bulk}
              alt=""
            />
            <span>Bulk Import (CSV, etc.)</span>
          </div>
        </Link>
      </div>
    );
  };
  

export default DealFilter

import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  CircularProgress,
  IconButton,
  Avatar,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import Endpoint from "../../../utils/Endpoint";
import "./AddTaskPopup.scss";
import { useSelector } from "react-redux";

export function AddTaskPopup({
  isOpen,
  onClose,
  selectedDate,
  idToken,
  userInformation,
  getdata,
}) {
  const [taskTitle, setTaskTitle] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [taskDate, setTaskDate] = useState(selectedDate);
  const [taskTime, setTaskTime] = useState("");
  const [assetType, setAssetType] = useState(""); // New state for asset type
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setTaskDate(selectedDate);
  }, [selectedDate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    const taskData = {
      user_id: userInformation?._id,
      Name: taskTitle,
      date: taskDate,
      desc: taskDescription,
      time: taskTime,
      assetType, // Include assetType in task data
    };

    try {
      const config = {
        headers: {
          "auth-token": idToken,
        },
      };
      await axios.post(Endpoint.addtask, taskData, config);
      await getdata();
      onClose();
    } catch (err) {
      setError("An error occurred. Please refresh this page.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        <Box className="dialog-title-container">
          Create New Task
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {error && <p className="dialog-error-message">{error}</p>}
        <Box className="avatar-container">
          <Avatar
            className="avatar_image"
            alt={userInformation?.name}
            src={userInformation?.profile_image}
          />
        </Box>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Task Name"
            placeholder="Task Name"
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            value={taskTitle}
            onChange={(e) => setTaskTitle(e.target.value)}
            required
          />
          <TextField
            label="Description"
            placeholder="Description..."
            multiline
            fullWidth
            rows={3}
            margin="normal"
            value={taskDescription}
            onChange={(e) => setTaskDescription(e.target.value)}
            required
          />
          <TextField
            label="Date"
            type="date"
            fullWidth
            margin="normal"
            value={taskDate}
            onChange={(e) => setTaskDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            required
          />
          <TextField
            label="Time"
            type="time"
            fullWidth
            margin="normal"
            value={taskTime}
            onChange={(e) => setTaskTime(e.target.value)}
            InputLabelProps={{ shrink: true }}
            required
          />
          <FormControl fullWidth margin="normal" required>
            <InputLabel>Asset Type</InputLabel>
            <Select
              value={assetType}
              onChange={(e) => setAssetType(e.target.value)}
              label="Asset Type"
            >
              <MenuItem value="Tours">Tours</MenuItem>
              <MenuItem value="Brokers">Brokers</MenuItem>
              <MenuItem value="Tenants">Tenants</MenuItem>
              <MenuItem value="Internal Office">Internal Office</MenuItem>
            </Select>
          </FormControl>
          <Box className="form-buttons-container">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={loading}
              className="submit-button"
            >
              {loading ? (
                <CircularProgress size={24} style={{ color: "white" }} />
              ) : (
                "Create Task"
              )}
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
}

import React, {useState, useEffect} from "react";
import {Dialog, DialogTitle, DialogContent, DialogActions, Button} from "@mui/material";
import "./Feedback.scss";
import axios from "axios";
import {reasons} from "./reasons";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Endpoint from "../../../utils/Endpoint";
import useAuthConfig from "../../../utils/Config";
import {TaskAssignedToast, OpenAIErrorToast} from "../../../components/Tostify/SuccessToast/SuccessTostify";

const FeedbackForm = ({open, onClose, user_id, asset_id, Message_id, messageid}) => {
  const [selectedReason, setSelectedReason] = useState([]);
  const [otherFeedback, setOtherFeedback] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const {idToken} = useSelector((state) => state.auth);
  const {userInformation} = useSelector((state) => state.auth);
  const {selectedProperty} = useSelector((state) => state.portfolio);
  const config = useAuthConfig();
  const [selectedUsers, setSelectedUsers] = useState(["User1", "User2"]);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (open) {
      // Reset inputs when the dialog opens
      setSelectedReason([]);
      setOtherFeedback("");
      setError(null);

      const fetchFeedback = async () => {
        setLoading(true);
        setError(null);

        try {
          const response = await axios.get(`${Endpoint.getfeedback}/?user_id=${user_id}&asset_id=${asset_id}&Message_id=${Message_id}`);
          const data = response.data;
          setOtherFeedback(data.data.other || "");
        } catch (err) {
          setError("An error occurred while fetching feedback.");
        } finally {
          setLoading(false);
        }
      };

      fetchFeedback();
    }
  }, [open, user_id, asset_id, Message_id]);

  const handleReasonClick = (reason) => {
    setSelectedReason((prevSelected) => (prevSelected.includes(reason) ? prevSelected.filter((item) => item !== reason) : [...prevSelected, reason]));
  };

  const handleInputChange = (e) => {
    setOtherFeedback(e.target.value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);

    try {
      if (!idToken) {
        toast.error("❌ Access denied. Authentication token missing.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
        setLoading(false);
        return;
      }

      const feedbackTypeIndices = selectedReason?.map((reason) => reasons.indexOf(reason) + 1);

      await axios.post(
        Endpoint.sendfeedback,
        {
          feedback_type: feedbackTypeIndices,
          other: otherFeedback,
        },
        {
          params: {
            user_id: userInformation?._id,
            asset_id: selectedProperty?._id,
            Message_id: messageid,
          },
          ...config,
        },
      );

      toast.success("✅ Your feedback has been submitted successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });

      onClose();
    } catch (err) {
      const message = err.response?.data?.message || "An error occurred while submitting feedback.";

      toast.error(`❌ ${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });

      setErrorMessage(message);
      setError(message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      className="dislikeFeedbackContainer"
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "20px",
        },
      }}
    >
      <DialogTitle
        sx={{
          fontFamily: "Roboto",
          fontSize: "16px",
          fontWeight: 800,
          lineHeight: "19px",
          textAlign: "left",
          textUnderlinePosition: "from-font",
          textDecorationSkipInk: "none",
        }}
      >
        Help Us Make it Better
      </DialogTitle>

      <DialogContent>
        <div className="reasons-container">
          {reasons?.map((reason) => (
            <button
              key={reason}
              className={`reason-button ${selectedReason.includes(reason) ? "selected" : ""}`}
              onClick={() => handleReasonClick(reason)}
            >
              {reason}
            </button>
          ))}
        </div>
        <div className="other-feedback">
          <label>Others</label>
          <input
            type="text"
            placeholder="Please feel free to add any relevant specifics."
            value={otherFeedback}
            onChange={handleInputChange}
          />
        </div>
      </DialogContent>
      <DialogActions style={{justifyContent: "flex-start"}}>
        <Button
          onClick={handleSubmit}
          color="primary"
          disabled={loading}
          className="SubmitFeedback_button"
        >
          {loading ? (
            <CircularProgress
              size={20}
              color="inherit"
            />
          ) : (
            "Submit"
          )}
        </Button>
      </DialogActions>
      <div>
        {showSuccessToast && (
          <TaskAssignedToast
            selectedUsers={selectedUsers}
            onViewTasks={() => console.log("Navigating to Task Page")}
          />
        )}
        {showErrorToast && (
          <OpenAIErrorToast
            errorMessage={errorMessage}
            onClose={() => setShowErrorToast(false)}
          />
        )}
      </div>
    </Dialog>
  );
};

export default FeedbackForm;

import React, {useCallback, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import "./Disposition.scss";
import SummaryImg from "../../assets/portfolio/images/edit-asset-pic.png";
import Checkbox from "../profile/settings/Checkbox";
import info from "../../assets/svg/info.svg";
import Card from "./Card";
import {useSelector} from "react-redux";
import axios from "axios";
import Endpoint from "../../utils/Endpoint";

const Disposition = () => {
  const {selectedProperty} = useSelector((state) => state.portfolio);
  const {idToken} = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [offMarket, setOffMarket] = useState(false);
  let navigate = useNavigate();
  let {userInformation} = useSelector((state) => state.auth);

  const sendAssetData = useCallback(
    async (assetId) => {
      try {
        setLoading(true);
        const config = {
          headers: {
            "auth-token": idToken,
          },
        };
        await axios.post(Endpoint.disposition, {assetId, off_Market: offMarket}, config);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [idToken, offMarket],
  );

  const handleStartSyndicate = async (e) => {
    e.preventDefault();
    if (selectedProperty?._id) {
      await sendAssetData(selectedProperty?._id);
      navigate(`/${userInformation._id}/asset-discovery`);
    }
  };

  return (
    <div className="disposition-container">
      <div className="asset-header">
        <h1>Disposition</h1>
        <div className="header-buttons">
          <Link to={`/${selectedProperty?._id}/portfolio`}>Discard changes and Exit</Link>
          <a
            href={`/${selectedProperty?._id}/asset-discovery`}
            onClick={handleStartSyndicate}
            className={`start-syndicate-button ${loading ? "loading" : ""}`}
          >
            {loading ? <div className="spinner"></div> : "Start Disposition"}
          </a>

          <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
            <Checkbox
              label={"Off Market"}
              checked={offMarket}
              onChange={setOffMarket}
            />
            <img
              src={info}
              alt="info"
              style={{height: "20px", width: "20px"}}
            />
          </div>
        </div>
      </div>
      <div className="asset-container">
        <div className="main-container">
          <Card type={"AssetName"} />
          <Card type={"Description"} />
          <Card type={"Price"} />
          <Card type={"Contact"} />
          <Card type={"Website"} />
          <Card type={"Photos"} />
          <Card type={"Marketing"} />
          <Card type={"PropertyDetails"} />
          <Card type={"Utilities"} />
          {/* <Card type={"Asset"} /> */}
          {/* <Card type={"Manager"} />
          <Card type={"Tags"} /> */}
        </div>
        <div className="side-container">
          <Sidecard />
        </div>
      </div>
    </div>
  );
};

const Sidecard = () => {
  const {selectedProperty} = useSelector((state) => state.portfolio);

  return (
    <div className="sidecard-container">
      <div className="sidecard-header">
        <h2>Summary</h2>
      </div>
      <div className="sidecard-content">
        <img
          src={selectedProperty?.assetsImages[0] || SummaryImg}
          alt={selectedProperty?.name || "Summary Image"}
        />
        <h4>${selectedProperty?.assetValue}</h4>
        <div className="property-details">
          <h6>Name</h6>
          <p>{selectedProperty?.assetName}</p>
          <h6>Address</h6>
          <p>{selectedProperty?.assetsAddress}</p>
          <h6>Post Code</h6>
          <p>{selectedProperty?.postcode || "Null"}</p>
          <h6>Country</h6>
          <p>{selectedProperty?.country || "US"}</p>
          <h6>Phone No.</h6>
          <p>{selectedProperty?.phone || "123456789"}</p>
          <h6>E-mail</h6>
          <p>{selectedProperty?.addedByEmail}</p>
          <h4>LOT</h4>
          <h4>&nbsp;</h4>
          <h6>Asset type</h6>
          <p>{selectedProperty?.property_type}</p>
          <h6>Lot Area</h6>
          <p>{selectedProperty?.lotArea || "N/A"}</p>
          <h6>Zoning</h6>
          <p>{selectedProperty?.zoning || "N/A"}</p>
        </div>
      </div>
    </div>
  );
};

export default Disposition;

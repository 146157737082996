import counterReducer from "./counter";
import loggedReducer from "./isLogged";
import ipdReducer from "./ipd";
import authSlice from "./authSlice";
import {combineReducers} from "redux";
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./user";
import messageReducer from "./message";
import errorReducer from "./error";
import userDetailsSlice from "./userDetailsSlice";
import portfolioSlice from "./portfolioSlice";
import assetsReducer from "./assetsSlice";
import userPermissionsReducer from "./userPermissions";
import notificationReducer from "./notificationSlice";
import checklistReducer from "./checklist";
import dealsSlice from "./deals";
import AdminGivenAccessSlice from "./AdminGivenAccess";
import dispositionslice from "./Disposition";
import tenantDataReducer from "./AssetDashboardGraph";
import financialSliceReducer from "./financialSlice";
import crowdfundingSliceReducer from "./crowdfundingSlice";
import DealsSyndicateSliceReducer from "./DealsSyndicate";
import OperatingIncomeExpencesSliceReducer from "./Graphs/OperatingIncomeExpences";
import UnitTurnoverSliceReducer from "./Graphs/UnitTurnover";
import BudgetSliceReducer from "./Graphs/Budget";
import investmentSliceReducer from "./investment/investmentSlice";
import Portfolio_DashboadSliceReducer from "../reducers/Portfolio Dashboard/Portfolio_Dashboad";
import dealsroomSliceReducer from "../reducers/DealsRoom/dealsroomSlice";
import InviteInvestorSliceReducer from "../reducers/InviteInvestor";
import OnlyUserDataSlide from "./UserData/OnlyUserData";
import seletedtaskcalanderSlideReducer from './Task/SeletedTask'
import openaiSlideReducer from './OpenAIChat/OpenAICHAT'

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["walletBalance", "user"],
};

const rootReducer = combineReducers({
  counter: counterReducer,
  isLogged: loggedReducer,
  walletBalance: ipdReducer,
  user: userReducer,
  auth: authSlice,
  OnlyUserSlide: OnlyUserDataSlide,
  mesage: messageReducer,
  error: errorReducer,
  userInfo: userDetailsSlice,
  portfolio: portfolioSlice,
  userPermissions: userPermissionsReducer,
  notifications: notificationReducer,
  checklist: checklistReducer,
  AssessbyEmail: assetsReducer,
  deals: dealsSlice,
  AdminGivenAccess: AdminGivenAccessSlice,
  disposition: dispositionslice,
  tenantData: tenantDataReducer,
  financialSlice: financialSliceReducer,
  crowdfundingSlice: crowdfundingSliceReducer,
  DealsSyndicateSlice: DealsSyndicateSliceReducer,
  OperatingIncomeExpencesSlice: OperatingIncomeExpencesSliceReducer,
  UnitTurnoverSlice: UnitTurnoverSliceReducer,
  BudgetSlice: BudgetSliceReducer,
  investmentSlice: investmentSliceReducer,
  Portfolio_DashboadSlice: Portfolio_DashboadSliceReducer,
  dealsroomSlice: dealsroomSliceReducer,
  InviteInvestor: InviteInvestorSliceReducer,
  seletedtaskcalander:seletedtaskcalanderSlideReducer,
  // openai:openaiSlideReducer,
  openaimessages:openaiSlideReducer

});

export default persistReducer(persistConfig, rootReducer);

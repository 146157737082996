import React from "react";
import "./ChatWithOpenAI.scss";
import chatbackgroundimage from "../../assets/Openai/Chatbackground.png";
import sendbutton from "../../assets/Openai/send_button.png";
import gptlogo from "../../assets/Openai/logoforgpt.svg";
import user from "../../assets/Openai/userlogoforgpt.svg";
import {MdOutlineAttachFile} from "react-icons/md";

const ChatWithOpenAI = () => {
  return (
    <div className="openai-chat-page">
      <div className="openai-sidebar">
        <button className="openai-new-chat-button">+ New Chat</button>

        <div className="openai-sidebar-section">
          <h3>Previous 7 Days</h3>
          <p className="openai-sidebar-item">Lorem ipsum dolor sit amet</p>
          <p className="openai-sidebar-item">Lorem ipsum dolor sit amet ...</p>
        </div>

        <hr className="openai-sidebar-divider" />

        <div className="openai-sidebar-section">
          <h3>Previous 30 Days</h3>
          <p className="openai-sidebar-item">Lorem ipsum dolor sit amet</p>
          <p className="openai-sidebar-item">Lorem ipsum dolor sit amet</p>
        </div>

        <hr className="openai-sidebar-divider" />

        <div className="openai-sidebar-section">
          <h3>Last Month</h3>
          <p className="openai-sidebar-item">Lorem ipsum dolor sit amet</p>
          <p className="openai-sidebar-item">Lorem ipsum dolor sit amet</p>
          <p className="openai-sidebar-item">Lorem ipsum dolor sit amet</p>
          <p className="openai-sidebar-item">Lorem ipsum dolor sit amet</p>
          <p className="openai-sidebar-item">Lorem ipsum dolor sit amet</p>
        </div>
      </div>

      <div
        className="openai-chat-content"
        style={{
          backgroundImage: `url(${chatbackgroundimage})`, 
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="openai-chat-messages">
          {/* ChatGPT message */}
          <div className="openai-message openai-received">
            <img
              className="openai-user-icon"
              src={gptlogo}
              alt="ChatGPT"
            />
            <div className="openai-message-bubble">
              <p>eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</p>
            </div>
          </div>

          {/* User message */}
          <div className="openai-message openai-sent">
            <img
              className="openai-user-icon"
              src={user}
              alt="User"
            />
            <div className="openai-message-bubble">
              <p>Lorem ipsum dolor sit amet</p>
            </div>
          </div>
        </div>

        {/* Send Message */}

        <div class="openai-chat-input">
          <MdOutlineAttachFile />
          <input
            type="text"
            placeholder="Ask Your Queries..."
          />
          <button class="send-btn">
            <img
              src={sendbutton}
              alt="Send"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatWithOpenAI;
